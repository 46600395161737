.reviewListing-wrp {
  max-width: 100%;
  margin: 0 auto;
  float: left;

  .each-reviews {
    border: 1px solid #AAAAAA;
    padding: 25px;
    border-radius: 13px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 5px;

    h2 {
      margin: 0;
      padding-bottom: 5px;
      font-weight: 400;
      text-align: left;

      svg {
        width: 18px;
        height: 18px;
      }
    }

    p {
      margin: 0;
      font-size: 15px;
      text-align: left;
      padding-bottom: 25px;
    }

    .review-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 25px;

      p {
        padding-bottom: 0;
        font-size: 13px;
        color: var(--text80);
      }

      h2 {
        padding: 0;
        line-height: 1.1;
      }
    }

    .review-foot {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 15px;
      border-top: 1px solid #AAAAAA;

      p {
        padding-bottom: 0;
        font-size: 13px;
        color: var(--text80);
      }

      img {
        height: 20px;
        width: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    background-color: var(--Text);

    svg {
      path {
        stroke: var(--white);
      }
    }
  }

  .swiper,
  .swiper-wrapper,
  .swiper-slide {
    height: unset !important;
  }

  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: var(--swiper-navigation-sides-offset, -50px);

    @media only screen and (max-width: 1200px) {
      & {
        left: var(--swiper-navigation-sides-offset, -10px);
      }
    }
  }

  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: var(--swiper-navigation-sides-offset, -50px);
    @media only screen and (max-width: 1200px) {
      & {
        right: var(--swiper-navigation-sides-offset, -10px);
      }
    }

  }

}
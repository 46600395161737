.about-main-wrapper {
  background: #fff;
  padding: 15px 0 115px;

  .abt-img-wrp {
    span {
      max-width: 295px;
      display: block;
      margin: 0 auto;
      margin-bottom: 20px;
    }
  }

  .abt-cont-wrp {
    max-width: 1080px;
    margin: 0 auto;
    float: none;
    display: table;

    .abt-main-header {
      font-weight: 400;
      font-size: 26px;
      margin: 0 auto;
      margin-bottom: 50px;
      text-align: center;
      // max-width: 765px;
      text-transform: uppercase;
      line-height: 1.8;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      margin: 0 auto;
      margin-bottom: 40px;
    }

    label {
      font-weight: 400;
      font-size: 18px;
      display: block;
      margin: 0 auto;
      text-transform: uppercase;
      margin-bottom: 20px;
      letter-spacing: 2px;
    }
  }

  .team-wrp-sec {
    display: flex;
    max-width: 1080px;
    margin: 0 auto;
    float: none;

    .team-intro-wrp {
      flex: 1;
      max-width: 270px;
      padding: 40px;
      background: #1D1D1B;
      color: #ffffff;

      label,
      p {
        color: inherit;
      }

      label {
        font-size: 20px;
        font-weight: 400;
        text-transform: uppercase;
      }

      p {
        font-weight: normal;
        font-size: 20px;
        font-family: 'Microsoft JhengHei';
      }

      span {
        position: absolute;
        left: 0;
        bottom: 0;

        img {
          height: 100%;
          object-position: bottom;
          object-fit: contain;
        }
      }
    }

    .team-detail-wrp {
      max-width: 100%;
      flex: 3;

      .each-team-sec {
        display: flex;
        margin-bottom: 20px;
        .team-detail-sec {
          padding: 20px 0;
          padding-right: 40px;
          // background: linear-gradient(90deg, #134870 0%, rgba(29, 29, 27, 0.46) 100%);
          color: #000000;
          min-height: 320px;
          display: flex;
          flex-direction: column;
          justify-content: center;

          label,
          p {
            color: inherit;
          }

          label {
            font-weight: 700;
            font-size: 18px;
            text-transform: uppercase;
            color: #134870;

            b {
              font-weight: normal;
              display: block;
              text-transform: capitalize;
            }
          }

          p {
            font-size: 16px;
            font-family: 'Microsoft JhengHei';
            font-weight: normal;
          }
        }

        .team-img-sec {
          max-width: 260px;

          span {
            display: block;
            width: 100%;
            height: 100%;
            position: relative;
            overflow: hidden;

            img {
              position: absolute;
              left: 0;
              top: 0;
              object-fit: cover;
              object-position: center;
              height: 100%;
            }
          }
        }

        &:nth-child(even) {
          flex-direction: row-reverse;
          .team-detail-sec{
            padding-right: 0;
            padding-left: 40px;
          }
        }
      }
    }
  }


.abt-cont-wrp{
  .contact-block-wrp{
    text-align: center;
    h4,p{
      color: #134870;
    }
  }
}

.value-main-wrpp.our-value-wrp.f-wrp{
  background: #ffffff;
  padding: 20px 0 20px;
  .value-header.f-wrp{
    padding-left: 0px;
    padding-right: 15px;
    label{
      font-weight: 400;
      font-size: 18px;
      font-family: 'Microsoft JhengHei';
      text-transform: uppercase;
    }
  }
}
.value-main-wrpp .each-blk-sec {
  padding-bottom: 30px;
  padding-left: 0;
}

.value-grid-wrp .each-abt-blk {
  border: none;
  box-shadow: none;
  padding-left: 0;
  padding-right: 0;
}


.value-grid-wrp .each-abt-blk .icon-blk span{
  width: 40px;
  height: 60px;
}








  @media only screen and (max-width: 600px) {
    & {
      padding: 50px 0 50px;
    }
  }

}

@media only screen and (max-width: 991px) {
  .about-main-wrapper .team-wrp-sec{
    flex-direction: column;
  }
  .about-main-wrapper .team-wrp-sec .team-intro-wrp{
    max-width: 100%;
    & img{
      display: none;
    }
  }
  
}

@media only screen and (max-width: 767px) {
  .about-main-wrapper .team-wrp-sec .team-detail-wrp .each-team-sec{
    .team-detail-sec{
      padding-left: 0;
        padding-right: 20px;
    }
    
    &:nth-child(even) {
      .team-detail-sec{
        padding-right: 0;
        padding-left: 20px;
      }
    }
  }
  .about-main-wrapper .team-wrp-sec .team-detail-wrp .each-team-sec .team-detail-sec p{
    font-size: 14px;
  }
  .contact-form-wrp.f-wrp{
    text-align: center;
    .foot-whtsap-btn{
      margin: 0 auto;
    }
  }
  .social-list.f-wrp{
    max-width: 100% !important;
    ul{
      justify-content: center;
    }
  }

  
}

@media only screen and (max-width: 600px) {
  .about-main-wrapper .team-wrp-sec .team-intro-wrp p{
    display: none;
  }
  .about-main-wrapper .team-wrp-sec .team-intro-wrp{
    padding: 0;
    background: transparent;
    margin-bottom: 25px;
  }
  .about-main-wrapper .team-wrp-sec .team-intro-wrp label{
    color: #1D1D1B;
    
  }
  .about-main-wrapper .team-wrp-sec .team-detail-wrp .each-team-sec{
    .team-detail-sec{
      padding-left: 15px;
        padding-right: 15px;
    }
    
    &:nth-child(even) {
      .team-detail-sec{
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }
  .about-main-wrapper .team-wrp-sec .team-detail-wrp .each-team-sec .team-detail-sec p{
    font-size: 14px;
  }
  .about-main-wrapper .team-wrp-sec .team-detail-wrp .each-team-sec{
    flex-direction: column-reverse;
  }
  .about-main-wrapper .team-wrp-sec .team-detail-wrp .each-team-sec:nth-child(even){
    flex-direction: column-reverse;
  }
  .about-main-wrapper .team-wrp-sec .team-detail-wrp .each-team-sec .team-img-sec{
    height: auto;
    max-width: 100%;
    margin: 0 auto;
  }
  .about-main-wrapper .team-wrp-sec .team-detail-wrp .each-team-sec .team-img-sec span img{
    position: relative;
  }
  .about-main-wrapper .team-wrp-sec .team-detail-wrp .each-team-sec .team-detail-sec{
    min-height: auto;
  }
  .about-main-wrapper .team-wrp-sec .team-detail-wrp .each-team-sec .team-detail-sec label{
    position: absolute;
    bottom: 100%;
    left: 0;
    z-index: 1;
    width: 100%;
    padding: 20px 24px;
    font-size: 16px;
    background: linear-gradient(90deg, #134870 0%, rgba(29, 29, 27, 0.15) 100%);
    color: #fff;
  }
  
}
.article-main-wrapper {
  &.about-main-wrapper {
    background: #F8F8F8;
    padding: 0px 0 115px;
  }

  .main-banner-wrp.inner-banner.f-wrp {
    min-height: 200px;
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: center;

    label {
      z-index: 1;
      text-transform: capitalize;
      font-size: 1.5rem;
      font-weight: 700;
      letter-spacing: 1px;
    }
  }

  .breadcrumb {
    padding: 45px 0;

    a {
      display: inline-block;
      font-size: 12px;
      color: #1D1D1B;
      font-weight: 600;

      img {
        display: inline;
        width: 6px;
        margin-right: 10px;
        object-fit: contain;
        object-position: center;
        position: relative;
        top: 1.5px;
      }
    }
  }

  .article-main-block {
    .article-list-box {
      height: 100%;
      padding-bottom: 30px;

      .each-article-box {
        display: block;
        height: 100%;

        .article-img {
          width: 100%;
          height: 185px;
          display: block;

          .rc {
            height: 100%;
          }

          img {
            height: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 10px 10px 0 0;
          }
        }

        .article-detail-box {
          padding: 20px 25px 25px;
          height: calc(100% - 185px);
          background: #FFFFFF;
          border: 1px solid #C6C6C6;
          border-radius: 0 0 10px 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .info-box {
            display: flex;
            justify-content: space-between;
            flex-direction: row-reverse;
            padding-top: 15px;

            p {
              margin-top: 10px;
              font-size: 12px;
              margin-bottom: 0;
            }
          }

          span {
            color: #1D1D1B;
            display: block;
            font-size: 18px;
            font-weight: 700;
            line-height: 1.3;
            margin-bottom: 10px;
            white-space: pre-line;
          }

          p {
            color: #1D1D1B;
            font-size: 14px;
            margin-top: 20px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .article-cont-wrp {
    padding-top: 55px;

    .contact-block-wrp {
      text-align: center;

      h4,
      p {
        color: #134870;
      }
    }
  }
}


.detailed-article-wrp {
  .breadcrumb {
    padding: 45px 0;
    max-width: 1080px;
    margin: 0 auto;
    float: none;
    display: table;
  }

  .article-con-block {
    max-width: 1080px;
    margin: 0 auto;
    float: none;
    display: table;

    .article-list-box {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }

    .article-img {
      height: 350px;
      max-height: 350px;
      display: block;

      .rc {
        height: 100%;
      }

      img {
        height: 100%;
        border-radius: 10px;
        object-fit: cover;
        object-position: center;
      }
    }

    .article-detail-box {
      padding: 20px 25px 30px;
      background: #FFFFFF;
      border: 1px solid #C6C6C6;
      border-radius: 10px;

      .title-with-like {
        float: right;
      }

      h1 {
        color: #1D1D1B;
        display: block;
        font-size: 22px;
        font-weight: 700;
        line-height: 1.3;
        margin-top: 0px;
        white-space: pre-line;
        margin-bottom: 15px;
      }

      &>span {
        color: #1D1D1B;
        display: block;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.3;
        margin-bottom: 10px;
        white-space: pre-line;
      }

      span {
        color: #1D1D1B;
      }

      p {
        color: #1D1D1B;
        font-size: 14px;
        margin-top: 25px;
      }

      .comment-form {
        padding-top: 15px;

        .MuiButtonBase-root {
          margin-top: 15px;
          color: #134870;
          border: 1px solid #134870;
          padding: 5px 35px;
          text-transform: capitalize;

          &.Mui-disabled {
            opacity: 0.7;
          }
        }

        .row {
          gap: 15px;
          &>*{
            padding: 0;
          }
          @media (min-width: 992px) {
            .col-lg-6 {
              width: calc(50% - 7.5px);
            }
          }

          @media (min-width: 768px) {
            .col-md-6 {
              width: calc(50% - 7.5px);
            }
          }

        }



      }

      .comments-list {
        ul {
          border-top: 1px solid #f1f1f1;
          padding-top: 30px;
          margin-top: 30px;

          li {
            display: flex;
            gap: 15px;

            .profile-pic {
              width: 40px;
              height: 40px;
              display: block;
              border-radius: 100%;
              overflow: hidden;
              border: 1px solid #C6C6C6;
              flex-shrink: 0;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
              }
            }

            .shortName {
              width: 40px;
              height: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 600;
              line-height: 1;
              border-radius: 100%;
              overflow: hidden;
              color: var(--white);
              background-color: #134870;
              flex-shrink: 0;
              font-size: 14px;
              letter-spacing: 1px;
            }

            p {
              margin: 0;
            }

            &:not(:last-child) {
              margin-bottom: 30px;
            }

            .comment-info {
              display: flex;
              padding-top: 15px;
              gap: 8px;

              p {
                font-size: 12px;
              }

              .delete-btn {
                color: var(--danger);
                text-transform: capitalize;
                padding: 0;
                min-width: 0;
                min-height: 0;
                height: auto;
                line-height: 1;
                text-decoration: underline;
                font-size: 12px;
              }
            }
          }
        }
      }

    }
  }

}
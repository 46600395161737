.teamListing-wrp {
  max-width: 100%;
  margin: 0 auto;
  float: left;

  @media only screen and (max-width: 1200px) {
    & {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .each-team-members {
    padding: 11px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    span {
      box-shadow: 0px 4px 10px 0px var(--shadow40);
      border-radius: 100px 10px 50px 10px;
      display: block;
      // width: max-content;
      height: 320px;
      overflow: hidden;
      margin: 0 auto;

      img {
        width: 100%;
        height: 100%;
        // object-fit: contain;
        object-position: bottom;
        border-radius: 0;
      }
    }


    .team-title {
      padding-top: 15px;

      h3 {
        font-size: 20px;
        text-align: center;
        margin: 0;
        padding-bottom: 5px;
        font-weight: 600;
        text-align: center;
      }

      p {
        margin: 0;
        font-weight: 500;
        font-size: 12px;
        text-align: center;
        padding-bottom: 0px;
        color: var(--textBlue);
      }
    }


  }

  .team-slider {
    .swiper-slide {
      transform: scale(0.8);
      transition: 0.5s all;

      &.swiper-slide-prev,
      &.swiper-slide-next {
        transform: scale(0.9);
        transition: 0.5s all;
      }

      &.swiper-slide-active {
        transform: scale(1);
        transition: 0.5s all;
      }

    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    background-color: var(--Text);

    svg {
      path {
        stroke: var(--white);
      }
    }
  }

  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    top: var(--swiper-navigation-top-offset, 30%);
  }

  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    top: var(--swiper-navigation-top-offset, 30%);
  }

}
.filter-form {
  form {
    input::placeholder {
      font-weight: bold;
      font-weight: 400;
      opacity: 1;
      color: #000;
    }
    input::placeholder,
      input::-webkit-input-placeholder,
      input::-moz-placeholder,
      input:-ms-input-placeholder,
      .MuiInputBase-input::placeholder {
        color: #000
      }


    .MuiOutlinedInput-root {
      border-radius: 50px;
      background-color: var(--BG880);
      color: #000;
      font-size: 15px;

      input {
        padding: 10.5px 14px;
        padding-left: 25px;
        font-size: 15px;
        color: #000 !important;
        
      }
      
      
    }

    .MuiSelect-select {
      padding: 10.5px 14px;
      padding-left: 25px;

      span {
        // font-size: 0.875rem;
        font-size: 15px;
      }
    }

    .flex-box {
      display: flex;
      gap: 10px;

      .filter-popup-btn {
        padding: 0;
        min-width: 0;
        width: 40px;
        height: 40px;
        flex-shrink: 0;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    .pc-options {
      display: none;

      @media only screen and (min-width: 900px) {
        & {
          display: block;
        }
      }
    }
    .slider-title{
      color: rgb(0, 0, 0);
      font-size: 14px;
      font-weight: 400;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    }
  }
}

.mob-filter-box {
  display: block;

  @media only screen and (min-width: 900px) {
    & {
      display: none;
    }
  }
}



.filter-popup {
  max-width: calc(100% - 20px);
  margin: 0 auto;
  display: flex;
  align-items: center;

  .filter-option-head {
    position: absolute;
    right: 10px;
    top: 5px;

    button {
      padding: 0;
      min-width: 0;
      min-height: 0;
      max-height: max-content;
      height: max-content;

      svg {
        width: 20px;
        height: 20px;

        path {
          fill: var(--ThemeBG);
        }
      }
    }
  }

  .filter-option-form {
    max-width: 550px;
    margin: 0 auto;
    float: none;
    padding: 30px;
    border-radius: 15px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
    background-color: rgba(255, 255, 255, 0.7);
    // background-image: url('../../../assets/images/HomePage/bannerBG.png');
    // background-size: cover;
    // &::after {
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   bottom: 0;
    //   // background: rgba(0, 0, 0, 0.5); /* Adjust color and opacity */
    //   // z-index: 1; /* Ensure overlay is above the background */
    //   // background-image: url('../../../assets/images/HomePage/bannerBG.png');
    // // background-size: cover;
    // }

    label.MuiInputLabel-formControl {
      font-size: 13px;
    }

    .MuiInputBase-root.MuiOutlinedInput-root {
      width: 100%;

    }

    .MuiOutlinedInput-notched {
      border-color: #98948A;
    }

    textarea {
      color: var(--Text36);
    }

    .app-text-input-container {
      .MuiFormControl-root {
        .MuiInputBase-formControl {
          input {
            color: var(--Text36);
          }

          input:-internal-autofill-selected {
            background-color: #fff !important;
          }

          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          textarea:-webkit-autofill,
          textarea:-webkit-autofill:hover,
          textarea:-webkit-autofill:focus,
          select:-webkit-autofill,
          select:-webkit-autofill:hover,
          select:-webkit-autofill:focus {
            -webkit-text-fill-color: var(--Text36) !important;
            caret-color: var(--Text36) !important;

            .light-bg & {
              -webkit-text-fill-color: var(--Text36) !important;
              caret-color: var(--Text36) !important;
              -webkit-box-shadow: 0 0 0 300px var(--Text36) inset !important;
            }
          }
        }
      }
    }
  }

  .search-btn {
    background: var(--Text);
    color: var(--white);
    width: 100%;
    gap: 10px;
    border-radius: 50px;
    padding: 8px;
    margin-top: 15px;

    .MuiSvgIcon-root {
      width: 20px;
      height: 20px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }


}
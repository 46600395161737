.main-header.project-main-header.inside-home-wrp.f-wrp {
    background-color: transparent;
    position: absolute;
    left: 0;
    top: 0;

    .custom-navbar {
        background-color: transparent !important;
        box-shadow: 0px 0px 0px 0px #1D1D1B !important;
    }

    &+.content+.project-main-footer {
        display: none;
    }
    .custom-header {
        background: transparent;
        .logo-wrp img {
            width: 135px;
        }
    }
    &.scrolled{
        .custom-header {
            background: transparent;
            .logo-wrp img {
                padding-top: 10px;
                width: 120px;
            }
        }
    }
}

.main-banner-wrp.f-wrp {
    @media only screen and (max-width: 991px) {
        & {
            .banner-bg.f-wrp {
                img {
                    object-position: 32%;
                }
            }
        }
    }
    @media only screen and (max-width: 767px) {
        & {
            .banner-bg.f-wrp {
                &::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(19, 72, 112, 0.3);
                    // backdrop-filter: blur(4px);
                }

                img {
                    object-position: 10%;
                }
            }
        }
    }

    &.hero-main-wrp {
        .banner-main-content {
            background-image: none;
            -webkit-backdrop-filter: none;
            backdrop-filter: none;

            .contnt-wrp {
                justify-content: center;
                align-content: flex-start;
                align-items: inherit;
                max-width: 75%;
                text-align: left;
                float: right;
                // min-height: calc(100vh - 139px);
                padding-top: 65px;
                padding-bottom: 30px;
                @media only screen and (max-width: 767px) {
                    & {
                        max-width: 100%;
                    }
                }
                @media only screen and (max-width: 500px) {
                    & {
                        padding-top: 65px;
                    }
                }
                .banner-counter {
                    gap: 25px;
                    flex-direction: row;
                    align-items: flex-start;
                    h3{
                        font-size: 22px;
                    }

                    @media only screen and (max-width: 599px) {
                        & {
                            gap: 0;
                            flex-direction: column;
                            align-items: center;
                            text-align: center;
                            padding-top: 0;
                        }
                    }
                    @media only screen and (max-width: 600px) {
                        h3{
                         padding-bottom: 15px;
                        }
                     }
                    @media only screen and (max-width: 400px) {
                       h3{
                        font-size: 24px;
                       }
                    }
                    

                    .countBoxItem {
                        text-align: center;

                        .label {
                            margin-top: 0px;
                        }

                        .count {
                            font-size: 22px;
                            width: 50px;
                            height: 50px;
                            line-height: 50px;
                            @media only screen and (max-width: 400px) {
                                font-size: 16px;
                                width: 35px;
                                height: 35px;
                                line-height: 35px;
                            }
                        }
                    }
                }

                .banner-content {
                    align-items: flex-start;

                    @media only screen and (max-width: 767px) {
                        & {
                            text-align: center;
                        }
                    }

                    h3 {
                        text-align: left;
                        font-family: "Microsoft JhengHei";
                        color: #fff;
                        // font-size: 44px;
                        font-size: 28px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 1.5;
                        letter-spacing: 1px;
                        text-transform: uppercase;

                        @media only screen and (max-width: 991px) {
                            & {
                                // font-size: 32px;
                                font-size: 24px;
                            }
                        }

                        @media only screen and (max-width: 767px) {
                            & {
                                text-align: center;
                            }
                        }

                        @media only screen and (max-width: 500px) {
                            & {
                                // font-size: 26px;
                                font-size: 25px;
                            }
                        }
                        @media only screen and (max-width: 400px) {
                            &{
                            //  font-size: 22px;
                            font-size: 22px;
                            }
                         }
                         @media only screen and (max-width: 300px) {
                            &{
                             font-size: 20px;
                            }
                         }
                    }

                    p {
                        color: #fff;
                        // font-size: 26px;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 1.5;
                        margin: 0;
                        padding-top: 15px;

                        @media only screen and (max-width: 991px) {
                            & {
                                // font-size: 18px;
                                font-size: 17px;
                            }
                        }

                        @media only screen and (max-width: 767px) {
                            & {
                                text-align: center;
                            }
                        }

                        @media only screen and (max-width: 500px) {
                            & {
                                // font-size: 16px;
                                font-size: 15px;
                            }
                        }
                        @media only screen and (max-width: 400px) {
                            &{
                            //  font-size: 14px;
                            font-size: 14px;
                            }
                         }
                         @media only screen and (max-width: 300px) {
                            &{
                             font-size: 13px;
                            }
                         }
                    }

                    .banner-btn {
                        border-radius: 50px;
                        background: rgba(19, 72, 112, 0.75);
                        backdrop-filter: blur(2px);
                        color: #fff !important;
                        font-weight: 400 !important;
                    }
                }
            }
        }

        .theme-switcher.f-wrp {
            display: flex;
            justify-content: space-between;
            padding-bottom: 20px;
            @media only screen and (max-width: 767px) {
                & {
                    padding-bottom: 50px;
                }
            }

            .plan-btn {
                @media only screen and (max-width: 767px) {
                    & {
                        display: flex;
                        align-items: center;
                        &.traditional{
                            flex-direction: row-reverse;
                        }
                    }
                }
                p {
                    margin: 0;
                    color: #134870;
                    font-family: "Microsoft JhengHei";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 1.5;
                    text-shadow: 0.5px 0px #fff;
                    @media only screen and (max-width: 767px) {
                        & {
                            line-height: 1;
                        }
                    }
                }

                img {
                    width: 30px;
                    display: block;
                    @media only screen and (max-width: 767px) {
                        & {
                            width: 17px;
                            height: 17px;
                            object-fit: cover;
                        }
                    }
                }
                
            }
        }

        .hero-scrolling-sec {
            background: rgba(19, 72, 112, 0.50);
            backdrop-filter: blur(8px);
            color: #fff;
            padding: 12px 0;
            font-family: "Microsoft JhengHei";
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: 1;
            letter-spacing: 1.4px;

            .rfm-marquee-container {
                overflow: hidden;
            }
        }
    }
}

.mob-draw-wrp {
    .MuiBackdrop-root.MuiModal-backdrop {
        background-color: transparent;
    }
}
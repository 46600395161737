.match-making {
  .main-banner-wrp.inner-banner.f-wrp {
    min-height: 200px;
  }

  .makeit-intro.f-wrp {
    background: #fff;
    padding-top: 75px;
    padding-bottom: 35px;

    .makeit-intro-con {
      h1 {
        font-size: 28px;
      }
    }
  }

  .wrk-blk-sec.f-wrp {
    background: #F1F1F1;
    padding-top: 50px;

    h1 {
      margin: 0;
      font-size: 26px;
      font-weight: 100;
    }

    .wrk-blk-con {
      padding-top: 30px;

      .row {
        margin-left: -15px;
        margin-right: -15px;

        .each-blk-sec {
          margin-bottom: 50px;
        }

        .each-othrServ-blk {
          padding: 30px 50px;
          height: 100%;
          background: #FFFFFF;
          border: 1px solid #C6C6C6;
          border-radius: 10px;

          span {
            display: block;
            font-size: 20px;
            font-weight: 700;
            line-height: 1.3;
            margin-bottom: 10px;
            white-space: pre-line;
          }

          p {
            color: #1D1D1B;
            font-size: 14px;
            margin-top: 25px;
          }
        }
      }
    }
  }

  .contact-block-wrp.f-wrp {
    text-align: center;
    padding-top: 75px;
    padding-bottom: 175px;
    padding-left: 25px;
    padding-right: 25px;
    button{
      min-width: 550px;
      @media only screen and (max-width: 600px) {
        &{
          min-width: 100%;
        }
      }
    }
  }
}
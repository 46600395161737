.top-developers {
  .slick-slider .slick-arrow {
    height: 100%;
    top: 0;
    transform: translate(0, 0%);
    width: 30px;
    background-color: #0000004a;
    z-index: 9;

    .slick-prev {
      left: 0px;
    }

    .slick-next {
      right: 0px;
    }
  }
  .slick-track{
    display: flex;
    align-items: center;
    // gap: 100px;

  }

  .partner-slide.top-developers {
    @media only screen and (min-width: 1024px) {
      & {
        padding-left: 15px;
        padding-right: 15px;
      }
    }   
  }

  .each-partner-sec {
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: 100%;
    span {
      display: block;

      img {
        height: 100%;
        object-fit: contain;
        object-position: center;
      }

      &.developer1 {
        // width: 180.39px;
        // height: 55.47px;
        width: 145px;
        height: 115px;
      }

      &.developer2 {
        // width: 275px;
        // height: 38px;
        width: 145px;
        height: 115px;
      }

      &.developer3 {
        // width: 189.61px;
        // height: 63.2px;
        width: 145px;
        height: 115px;
      }

      &.developer4 {
        // width: 156.06px;
        // height: 142.62px;
        width: 145px;
        height: 115px;
      }

      &.developer5 {
        // width: 358px;
        // height: 142px;
        width: 145px;
        height: 115px;
      }

      &.developer6 {
        // width: 142px;
        // height: 142px;
        width: 145px;
        height: 115px;
      }
    }
  }
}
@use 'sass:math';
.service-grid-wrp {
  .row {
    justify-content: center;

    .each-blk-sec:last-child {
      .each-service-blk {
        margin-bottom: 0px;
      }
    }
  }

  .each-service-blk {
    text-align: center;
    font-family: 'Microsoft JhengHei';
    margin-bottom: 85px;

    .cont-blk {
      padding-top: 50px;
    }

    h2 {
      font-weight: 100;
      font-size: 28px;
      text-transform: uppercase;
      margin: 0;
    }

    p {
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 5px;
    }

    .banner-btn {
      background: #134870;
      color: #fff !important;
      width: 175px;
      padding: 10px 20px;
      border: 0.5px solid #134870;
      border-radius: 5px;
      text-transform: none;
    }
  }
}

.learn-more-form.f-wrp {
  max-width: 500px;
  background: #fff;
  margin: 0 auto;
  float: none;
  display: table;
  padding: 20px;
  margin-top: 85px;
  margin-bottom: 85px;
  border-radius: 10px;
}

.learn-more-form .form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.learn-more-form .form-header h2 {
  margin: 0;
  font-size: 18px;
}

.learn-more-form .form-header .close-btn {
  margin: 0;
  min-width: 20px;
  height: 20px;
  width: 20px;
  padding: 0;
}

.reverse {
  transform: rotate(180deg);
}

.selectList {
  padding: 0;
}

.selectList>div.MuiButtonBase-root {
  border: 1px solid #C6C6C6;
}

.selectList ul.MuiList-root li:not(:last-child) {
  border-bottom: 1px solid #000;
}

.selectList ul.MuiList-root li>div.MuiButtonBase-root {
  padding-left: 0;
  padding-right: 0;
}

.serviceList .MuiListItemIcon-root {
  min-width: auto;
}

.submit-btn {
  width: 100%;
  border: 1px solid #134870 !important;
  border-radius: 4px !important;
  max-width: 100% !important;
  color: #134870 !important;
}

.info-txt {
  font-size: 12px;
  color: #000;

  span {
    color: #134870;
    cursor: pointer;
  }
}

.MuiModal-root {
  overflow-x: hidden;
  overflow-y: auto;
}

.service-honey-comb-sec {
  padding: 120px 0 200px;
  border-bottom: 18px solid #134870;

  .honey-comb-head {
    text-align: center;

    h1 {
      text-align: center;
      font-family: "Square Peg";
      font-weight: 400;
      color: #134870;
      font-size: 64px;
      display: inline-block;
      margin: 0;
      padding-left: 5%;
      padding-right: 5%;
      position: relative;
      background: #F1F1F1;
      z-index: 1;
    }

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 18px;
      background-color: #134870;
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -9px;
    }
  }

  .service-honey-comb {
    padding-top: 50px;
  }
}


// start

$hexagon-width: 250px;
$hexagon-height: math.div($hexagon-width, 1.818);
$hexagon-margin: 25px;
$hexagon-title-color: #fff;
$hexagon-border-color: #fff;
$hexagon-border-color--hover: #72f88e;
$hexagon-image-color: #134870;
$hexagon-image-color2: #D9D9D9;
$honeycomb-max-width: 820px;
$viewport-threshold: math.div($hexagon-width, 1) + $hexagon-margin;

*,
*::before,
*::after {
  box-sizing: border-box;
}

.honeycomb {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
  align-items: center;
  max-width: $honeycomb-max-width;
  margin: 0 auto;
  padding: 0;
  cursor: pointer;
  transform: translateY(math.div($hexagon-height, 4));

  &-cell {
    flex: 0 1 $hexagon-width;
    max-width: $hexagon-width;
    height: $hexagon-height;
    margin: math.div($hexagon-height, 2.1) math.div($hexagon-margin, 2) $hexagon-margin;
    position: relative;
    padding: 0.5em;

    text-align: center;
    z-index: 1;

    &__title {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      hyphens: auto;
      word-break: break-word;
      text-transform: capitalize;
      color: #fff;
      font-size: 22px;
      transition: opacity 350ms;

      > img,
      svg {
        width: 45px;
      }
    }

    &__image {
      object-fit: cover;
      object-position: center;
    }

    &::before,
    &::after {
      content: '';
    }

    &::before,
    &::after,
    &__image {
      top: -50%;
      left: 0;
      width: 100%;
      height: 200%;
      display: block;
      position: absolute;
      clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
      z-index: -1;
      transform: scale(1.05);
    }

    &::before {
      background: $hexagon-border-color;
      transform: scale(1);
    }

    &::after {
      background: $hexagon-image-color;
      opacity: 1;
      transition: opacity 350ms;
    }

    &:nth-child(1)::after {
      background: $hexagon-image-color2;
      opacity: 1;

      &__title {
        p {
          color: #134870 !important;
        }
      }
    }

    &:nth-child(2)::after,
    &:nth-child(3)::after {
      background: $hexagon-image-color;
      opacity: 1;
    }

    &:nth-child(4)::after {
      background: $hexagon-image-color;
      opacity: 0;
    }

    &:nth-child(5)::after {
      background: $hexagon-image-color2;
      opacity: 1;
    }

    &:hover {
      .honeycomb-cell__title {
        color: #fff;
      }

      &::before {
        background: $hexagon-border-color--hover;
      }

      &::after {
        opacity: 1;
      }
    }
  }

  &__placeholder {
    display: none;
    opacity: 0;
    width: $hexagon-width;
    margin: 0 math.div($hexagon-margin, 2);  
  }
}

@media (max-width: #{math.div($viewport-threshold, 1) * 2}) { 
  .honeycomb-cell {
    margin: math.div($hexagon-margin, 2) + math.div($hexagon-height, 2) $hexagon-margin; 
  }
}

@media (min-width: #{math.div($viewport-threshold, 1) * 2}) and (max-width: #{math.div($viewport-threshold, 1) * 3}) { 
  .honeycomb-cell:nth-child(3n) {
    margin-right: calc(50% - math.div($hexagon-width, 2)); 
    margin-left: calc(50% - math.div($hexagon-width, 2));  
  }

  .honeycomb__placeholder:nth-child(3n + 5) {
    display: block;
  }
}

@media (min-width: #{math.div($viewport-threshold, 1) * 3}) and (max-width: #{math.div($viewport-threshold, 1) * 4}) { 
  .honeycomb-cell:nth-child(5n + 4) {
    margin-left: calc(50% - math.div($hexagon-width + $hexagon-margin, 1)); 
  }

  .honeycomb-cell:nth-child(5n + 5) {
    margin-right: calc(50% - math.div($hexagon-width + $hexagon-margin, 1)); 
  }

  .honeycomb__placeholder:nth-child(5n),
  .honeycomb__placeholder:nth-child(5n + 3) {
    display: block;
  }
}

@media (min-width: #{math.div($viewport-threshold, 1) * 4}) { 
  .honeycomb-cell:nth-child(7n + 5) {
    margin-left: calc(50% - math.div($hexagon-width * 1.5 + $hexagon-margin, 1)); 
  }

  .honeycomb-cell:nth-child(7n + 7),
  .honeycomb-cell:nth-child(7n + 5):nth-last-child(2) {
    margin-right: calc(50% - math.div($hexagon-width * 1.5 + $hexagon-margin, 1)); 
  }

  .honeycomb__placeholder:nth-child(7n + 7),
  .honeycomb__placeholder:nth-child(7n + 9),
  .honeycomb__placeholder:nth-child(7n + 11) {
    display: block;
  }
}

.honeycomb p {
  color: #fff !important;
}

ul.honeycomb li:nth-child(1) p {
  color: #1D1D1B !important;
}

ul.honeycomb li:nth-child(2) p {
  color: #fff !important;
}

ul.honeycomb li:nth-child(3) p {
  color: #fff !important;
}

ul.honeycomb li:nth-child(4) p {
  color: #1D1D1B !important;
}

ul.honeycomb li:nth-child(5) p {
  color: #1D1D1B !important;
}

.honeycomb-cell:nth-child(4)::after {
  background: #1d1d1b;
}

ul.honeycomb li p {
  margin-top: 0;
}

ul.honeycomb li:hover svg path {
  fill: #fff !important;
}



// end












.othr-service-sec-blk {
  .othr-blk-intro {
    h1 {
      font-weight: 100;
      margin: 0;
      margin-bottom: 30px;
    }

    .for-dev-box {
      background-color: #134870;
      padding: 45px 30px;
      border-radius: 7px;

      * {
        color: #fff;
      }

      h3 {
        font-weight: 400;
        margin: 0;
        margin-bottom: 30px;
      }

      p {
        margin: 0;
        font-weight: 100;
        font-size: 16px;
      }

      .contact-btn {
        width: 100%;
        background: #fff !important;
        text-align: center;
        color: #134870 !important;
        border-radius: 5px;
        margin-top: 40px !important;
        padding: 12px 40px;
        font-size: 13px !important;
        font-weight: bold !important;
        text-decoration: none !important;
      }
    }
  }

  .othrServ-blk.f-wrp {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 50px;
    grid-row-gap: 10px;
    height: 100%;
    padding-left: 30px;

    .each-othrServ-blk {
      max-width: 250px;
      display: flex;
      flex-direction: column;

      span {
        display: block;
        font-size: 26px;
        font-weight: 700;
        line-height: 1.3;
        margin-bottom: 10px;
        max-width: 200px;
      }

      p {
        color: #1D1D1B;
        margin: 0;
      }

      &:nth-child(1),
      &:nth-child(2) {
        justify-content: flex-start;
      }

      &:nth-child(3),
      &:nth-child(4) {
        justify-content: flex-end;
      }
    }
  }

  .for-investo-blk.f-wrp {
    margin-top: 120px;
    // min-height: 309px;
    display: flex;
    // justify-content: flex-end;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    text-align: center;

    .investo-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: none;

      img {
        height: 100%;
      }
    }

    .for-invest-blk {
      position: relative;
      z-index: 1;
      max-width: 370px;
      float: right;
      padding: 40px 30px;

      h2 {
        margin: 0;
        margin-bottom: 30px;
        color: #134870;
        font-weight: 400;
        font-size: 22px;
      }

      p {
        margin: 0;
      }

      .contact-btn {
        margin-top: 0px !important;
        width: 100%;
        text-align: center;
        border-radius: 7px;
        background-color: #134870 !important;
        color: #fff !important;
        min-width: 320px;

        @media only screen and (max-width: 500px) {
          & {
            min-width: 100%;
          }
        }
      }
    }
  }
}



@media only screen and (max-width: 1200px) {
  .honeycomb {
    max-width: 845px;
  }

}

@media only screen and (max-width: 991px) {
  .othr-service-sec-blk .othrServ-blk.f-wrp {
    padding-left: 0px;
    padding-top: 50px;
  }

  .service-honey-comb-sec .honey-comb-head h1 {
    font-size: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .othr-service-sec-blk .othrServ-blk.f-wrp {
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 30px;
  }

  .othr-service-sec-blk .for-investo-blk.f-wrp .for-invest-blk {
    max-width: 100%;
  }

  .service-honey-comb-sec .honey-comb-head h1 {
    font-size: 40px;
    max-width: 75%;
    padding-left: 0;
    padding-right: 0;
    line-height: 1;
  }

  .each-othrServ-blk.f-wrp {
    background: #fff;
    border: 1px solid #C6C6C6;
    max-width: 100% !important;
    padding: 20px;
    border-radius: 10px;
  }
}

@media only screen and (max-width: 567px) {
  .each-othrServ-blk.f-wrp {
    span {
      font-size: 16px !important;
      max-width: 130px !important;
    }

    p {
      font-size: 16px !important;
    }
  }

  .othr-service-sec-blk .for-investo-blk.f-wrp {
    margin-top: 70px;
  }
}

//maybe we can use it later

// $hexagon-width: 250px;
// $hexagon-height: $hexagon-width*.55;
// $hexagon-margin: 25px;
// $hexagon-title-color: #fff;
// $hexagon-border-color: #fff;
// $hexagon-border-color--hover: #72f88e;
// $hexagon-image-color: #134870;
// $hexagon-image-color2: #D9D9D9;
// $honeycomb-max-width: 820px;
// $viewport-thereshold: $hexagon-width + $hexagon-margin;

// *,
// *::before,
// *::after {
//   box-sizing: border-box;
// }

// .honeycomb {
//   display: flex;
//   flex-wrap: wrap;
//   list-style: none;
//   justify-content: center;
//   align-items: center;
//   max-width: $honeycomb-max-width;
//   margin: 0 auto;
//   padding: 0;
//   cursor: pointer;
//   transform: translateY(#{$hexagon-height/4});

//   &-cell {
//     flex: 0 1 $hexagon-width;
//     max-width: $hexagon-width;
//     height: $hexagon-height;
//     margin: $hexagon-height/2.1 $hexagon-margin/2 $hexagon-margin;
//     position: relative;
//     padding: 0.5em;

//     text-align: center;
//     z-index: 1;


//     &__title {
//       height: 100%;
//       display: flex;
//       flex-direction: column;
//       justify-content: center;
//       align-items: center;
//       hyphens: auto;
//       word-break: break-word;
//       text-transform: capitalize;
//       color: #fff;
//       font-size: 22px;
//       transition: opacity 350ms;

//       >img,
//       svg {
//         width: 45px;
//       }
//     }

//     &__image {
//       object-fit: cover;
//       object-position: center;
//     }

//     &::before,
//     &::after {
//       content: '';
//     }

//     &::before,
//     &::after,
//     &__image {
//       top: -50%;
//       left: 0;
//       width: 100%;
//       height: 200%;
//       display: block;
//       position: absolute;
//       clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
//       z-index: -1;
//       transform: scale(1.05);
//     }

//     &::before {
//       background: $hexagon-border-color;
//       transform: scale(1);
//     }

//     &::after {
//       background: $hexagon-image-color;
//       opacity: 1;
//       transition: opacity 350ms;
//     }

//     &:nth-child(1):after {
//       background: $hexagon-image-color2;
//       opacity: 1;

//       &__title {
//         p {
//           color: #134870 !important;
//         }
//       }
//     }

//     &:nth-child(2):after {
//       background: $hexagon-image-color;
//       opacity: 1;
//     }

//     &:nth-child(3):after {
//       background: $hexagon-image-color;
//       opacity: 1;
//     }

//     &:nth-child(4):after {
//       background: $hexagon-image-color;
//       opacity: 0;
//     }

//     &:nth-child(5):after {
//       background: $hexagon-image-color2;
//       opacity: 1;
//     }


//     &:hover {

//       .honeycomb-cell__title {
//         color: #fff;
//       }

//       &::before {
//         background: $hexagon-border-color--hover;
//       }

//       &::after {
//         opacity: 1;
//       }
//     }
//   }

//   &__placeholder {
//     display: none;
//     opacity: 0;
//     width: $hexagon-width;
//     margin: 0 $hexagon-margin/2;
//   }
// }

// @media (max-width:#{2*$viewport-thereshold}) {
//   .honeycomb-cell {
//     margin: #{$hexagon-margin/2 + $hexagon-height/2} $hexagon-margin;
//   }
// }

// @media (min-width: #{2*$viewport-thereshold}) and (max-width:#{3*$viewport-thereshold}) {
//   .honeycomb-cell:nth-child(3n) {
//     margin-right: calc(50% - #{$hexagon-width/2});
//     margin-left: calc(50% - #{$hexagon-width/2});
//   }

//   .honeycomb__placeholder:nth-child(3n + 5) {
//     display: block;
//   }
// }

// @media (min-width: #{3*$viewport-thereshold}) and (max-width:#{4*$viewport-thereshold}) {
//   .honeycomb-cell:nth-child(5n + 4) {
//     margin-left: calc(50% - #{$hexagon-width + $hexagon-margin});
//   }

//   .honeycomb-cell:nth-child(5n + 5) {
//     margin-right: calc(50% - #{$hexagon-width + $hexagon-margin});
//   }

//   .honeycomb__placeholder:nth-child(5n),
//   .honeycomb__placeholder:nth-child(5n + 3) {
//     display: block;
//   }
// }

// @media (min-width: #{4*$viewport-thereshold}) {
//   .honeycomb-cell:nth-child(7n + 5) {
//     margin-left: calc(50% - #{$hexagon-width*1.5 + $hexagon-margin});
//   }

//   .honeycomb-cell:nth-child(7n + 7),
//   .honeycomb-cell:nth-child(7n + 5):nth-last-child(2) {
//     margin-right: calc(50% - #{$hexagon-width*1.5 + $hexagon-margin});
//   }

//   .honeycomb__placeholder:nth-child(7n + 7),
//   .honeycomb__placeholder:nth-child(7n + 9),
//   .honeycomb__placeholder:nth-child(7n + 11) {
//     display: block;
//   }
// }

// .honeycomb p {
//   color: #fff !important;
// }

// ul.honeycomb li:nth-child(1) p {
//   color: #1D1D1B !important;
// }

// ul.honeycomb li:nth-child(2) p {
//   color: #fff !important;
// }

// ul.honeycomb li:nth-child(3) p {
//   color: #fff !important;
// }

// ul.honeycomb li:nth-child(4) p {
//   color: #1D1D1B !important;
// }

// ul.honeycomb li:nth-child(5) p {
//   color: #1D1D1B !important;
// }

// // ul.honeycomb li:hover p {
// //   color: #fff !important;
// // }

// .honeycomb-cell:nth-child(4):after {
//   // background: transparent;
//   background: #1d1d1b;
// }

// ul.honeycomb li p {
//   margin-top: 0;
// }

// ul.honeycomb li:hover svg path {
//   fill: #fff !important;
// }


.inside-traditional-wrp {
  &.project-main-header {
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
  }

  &.main-header {
    .custom-navbar{
      padding-top: 10px;
      background-color: transparent !important;
    }
    .custom-header {
      max-width: 1200px;
      .MuiTypography-root {
        & {
          .logo-wrp {
            display: none;
          }
        }
      }

      .logo-wrp {

        span {
          svg {
            path {
              stroke: #134870 !important;
            }
          }
        }
      }
    }

    &.scrolled {
      .custom-navbar {
        background: transparent !important;
      }

      .custom-header {
        background: transparent;
      }
    }
  }

  &+.content+.project-main-footer {
    box-shadow: none;
  }



}

.traditional-about-wrp {
  padding-top: 60px;
  min-height: 275px;
  @media only screen and (max-width: 767px) {
    padding-top: 0;
    min-height: 0;
  }
  .traditionalBG {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    @media only screen and (max-width: 767px) {
      position: relative;
    }
    img {
      height: 100%;
      object-position: top;
    }
  }
  .about-main-wrp{
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 62px;
    @media only screen and (max-width: 767px) {
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }
  h3{
    color: #134870;
    font-size: 22px;
  }
  p{
    color: #134870;
    font-size: 18px;
  }

  .theme-page-switch {
    ul {
      background: #FFFFFF;
      li{
        a{
          background: #FFFFFF;
        }
      }
    }
  }


}






.contact-btn {
  background: #134870 !important;
  color: #fff !important;
  padding: 8px 30px !important;
  text-transform: none !important;
}

.lookingTOInvest.MuiButtonBase-root {
  display: inline-block;
  background-color: var(--white) !important;
  color: var(--text) !important;
  padding: 12px 40px !important;
  font-size: 13px !important;
  font-weight: bold !important;
  text-decoration: none;
  text-transform: unset;
  line-height: 1.3;
  font-family: 'Microsoft JhengHei' !important;
  border-radius: 10px !important;
  margin-top: 25px;
  border: none;
  min-height: 20px !important;
}

#contact-modal {
  .MuiDialog-container {
    .MuiPaper-root.MuiPaper-elevation {
      max-width: 991px;
      border-radius: 15px;

      .MuiDialogContent-root {
        padding: 0;
      }

      .contct-grid-wrp.f-wrp {
        display: flex;
        padding: 50px 80px;
        background-color: #134870;
        background-image: url('../../assets/images/icons/popupbg1.png'), url('../../assets/images/icons/popupbg2.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left top, left top;
        // flex-wrap: wrap;

        .img-blk {
          min-width: 385px;
          min-height: 470px;
          border: 2px solid #fff;
          border-radius: 50px 0px 50px 0px;
          overflow: hidden;

          span {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: block;

            img {
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
        }

        .contct-form-wrp.f-wrp {
          padding-left: 50px;
          color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          min-width: 390px;

          h2 {
            font-size: 28px;
            line-height: 1;
            padding: 0;
          }

          .MuiFormControl-root {
            background: #fff;
            border-radius: 7px;
            overflow: hidden;
            margin-top: 8px;
            margin-bottom: 4px;

            label {
              left: 20px;
              top: -6px;
              font-size: 14px;
              color: #C6C6C6;
              font-weight: 100;
              font-family: 'Microsoft JhengHei';
              z-index: 9;

              &.MuiInputLabel-shrink {
                opacity: 0;
              }
            }
          }

          .MuiInputBase-root {
            margin-top: 0;

            &:hover,
            &::before,
            &::after {
              border: none !important;
            }

            input,
            textarea {
              padding-left: 20px;
              padding-right: 20px;
              background: #fff;
              height: 40px;
            }

            textarea {
              height: auto;
            }
          }

          button {
            background: #1D1D1B;
            color: #fff;
            border-radius: 7px;
            margin-top: 20px;
          }
        }
      }
    }
  }
}

.float-btn {
  background: transparent;
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 9;
  cursor: pointer;
}

@media only screen and (max-width: 991px) {
  #contact-modal .MuiDialog-container .MuiPaper-root.MuiPaper-elevation .contct-grid-wrp.f-wrp .img-blk {
    min-width: 250px;
  }

  #contact-modal .MuiDialog-container .MuiPaper-root.MuiPaper-elevation .contct-grid-wrp.f-wrp .contct-form-wrp.f-wrp {
    min-width: 300px;
  }


}

@media only screen and (max-width: 767px) {
  #contact-modal .MuiDialog-container .MuiPaper-root.MuiPaper-elevation .contct-grid-wrp.f-wrp {
    padding: 35px 40px;
  }

  #contact-modal .MuiDialog-container .MuiPaper-root.MuiPaper-elevation .contct-grid-wrp.f-wrp .img-blk {
    display: none;
  }

  #contact-modal .MuiDialog-container .MuiPaper-root.MuiPaper-elevation .contct-grid-wrp.f-wrp .contct-form-wrp.f-wrp {
    padding-left: 0;
    min-width: 100%;
  }

  #contact-modal .MuiDialog-container .MuiPaper-root.MuiPaper-elevation .contct-grid-wrp.f-wrp .contct-form-wrp.f-wrp h2 {
    font-size: 18px;
    margin-bottom: 15px;
  }

}

@media only screen and (max-width: 600px) {
  .page-main-wrapper.about-main-wrapper.f-wrp .container {
    padding-left: 0;
    padding-right: 0;
  }

  .about-main-wrapper .abt-cont-wrp,
  .about-main-wrapper .team-wrp-sec .team-intro-wrp {
    padding-left: 15px;
    padding-right: 15px;
  }

}
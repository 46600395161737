.listing-wrapper {
  background: #F8F8F8;
  padding-bottom: 200px;

  .main-banner-wrp.inner-banner.f-wrp {
    min-height: 200px;
  }

  .filter-box {
    display: flex;
    backdrop-filter: blur(10px);
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 30px 0 30px 0;
    overflow: hidden;
    padding: 15px 25px;
    margin-top: 80px;
    margin-bottom: 35px;
    // background: rgba(255, 255, 255, 0.3);
    position: relative;

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: rgba(255, 255, 255, 0.45);
      z-index: -1;
    }

    @media only screen and (max-width: 992px) {
      & {
        flex-direction: column-reverse;
        margin-top: 50px;
      }
    }

    @media only screen and (max-width: 500px) {
      & {
        padding: 15px;
      }
    }

    h4 {
      font-family: Poppins;
      font-size: 32.18px;
      font-weight: 500;
      margin: 0;
      text-align: center;
      width: 145px;
      line-height: 1;
      padding-bottom: 17px;

      @media only screen and (max-width: 992px) {
        & {
          padding-top: 25px;
          width: 100%;
        }
      }
    }

    .filter-head {
      display: none;

      @media only screen and (min-width: 900px) {
        & {
          display: block;
        }
      }
    }

    .search-btn {
      background: var(--Text);
      color: var(--white);
      width: 100%;
      gap: 10px;
      border-radius: 50px;
      padding: 8px;

      .MuiSvgIcon-root {
        width: 20px;
        height: 20px;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    .filter-form {
      padding-left: 25px;

      @media only screen and (max-width: 992px) {
        & {
          padding-left: 0px;
        }
      }
    }
  }





  .breadcrumb {
    padding: 45px 0;

    a {
      display: inline-block;
      font-size: 12px;
      color: #1D1D1B;
      font-weight: 600;

      img {
        display: inline;
        width: 6px;
        margin-right: 10px;
        object-fit: contain;
        object-position: center;
        position: relative;
        top: 1.5px;
      }
    }
  }

  .value-header h1 {
    padding-left: 0;
  }

  .each-list-blk {
    display: flex;
    background: #fff;
    padding: 15px;
    border-radius: 10px 10px 0px 0px;
    border: 0.5px solid #C6C6C6;

    &:not(:last-child) {
      margin-bottom: 0px;
    }

    .img-gallery-wrp {
      // display: flex;
      flex-wrap: wrap;
      gap: 10px;
      max-width: 550px;
      z-index: 9;

      .gallery-slider {
        padding-left: 0;
        padding-right: 0;
        height: 250px;
        left: 0;

        img {
          height: 250px;
        }

        .swiper-button-next {
          right: var(--swiper-navigation-sides-offset, 0px);
        }

        .swiper-button-prev {
          left: var(--swiper-navigation-sides-offset, 0px);
        }

        .swiper-button-next,
        .swiper-button-prev {
          width: 25px;
          background: rgba(0, 0, 0, 0.3);
          box-shadow: none;
          height: 100%;
          top: 0;
          margin-top: 0;
          border-radius: 0;
          color: #fff;
        }
      }

      .gallery-slider-thumb {
        height: 125px;
        margin-top: 15px;

        img {
          height: 125px;
        }
      }


      .carousel.carousel-slider {
        padding-left: 7px;
        padding-right: 7px;

        img {
          height: 250px;
        }
      }

      .carousel {
        .thumb {
          border: 5px solid #fff !important;
          margin-right: 0px;
          margin-bottom: 0;
          cursor: pointer;
        }

        .thumbs-wrapper {
          margin: 0px;
          overflow: hidden;

          // display: none;
          ul.thumbs {
            margin: 0;
            margin-top: 5px;

            li {
              img {
                height: 125px;
              }
            }
          }
        }
      }

      // .carousel.carousel-slider + .carousel{
      //   // width: 550px !important;
      // }

      .carousel .thumb.selected,
      .carousel .thumb:hover {
        border: 3px solid transparent;
      }

      .carousel .control-dots,
      .carousel .carousel-status {
        display: none;
      }

      span {
        border-radius: 5px;
        overflow: hidden;
        flex: 1 1 40%;

        &:nth-child(1) {
          flex: 1 1 100%;
          height: 350px;
        }

        &:nth-child(2) {
          height: 160px;
          max-width: 50%;
        }

        &:nth-child(3) {
          height: 160px;
          max-width: 50%;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 5px;
        cursor: pointer;
      }
    }




    .list-cont-sec.f-wrp {
      padding-left: 50px;
      padding-right: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .prop-detail-wrp {
        h1.prop-label,
        label.prop-label {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 24px;
          color: #134870;
          padding-bottom: 20px;
          font-weight: 500;
        }

        .location {
          display: flex;
          font-size: 16px;
          font-weight: 400;

          img {
            width: 20px;
            object-fit: contain;
            object-position: center;
            margin-right: 10px;
          }

          &+b {
            display: none;
          }
        }

        .flex-list.f-wrp {
          display: flex;
          justify-content: flex-start;
          align-items: flex-end;
          margin: 0;
          padding: 20px 0;

          li {
            max-width: 135px;
            margin-right: 30px;
          }

          .list-icon {
            width: 20px;
            display: block;
          }

          p {
            font-size: 14px;
            margin-top: 6px;
            line-height: 24px;
          }
        }

        .feature-list {

          label {
            font-weight: 700;
            font-size: 16px;
          }

          ul {
            list-style: disc;
            padding-left: 25px;
            margin-top: 10px;

            li {
              font-size: 14px;
              margin-bottom: 10px;
            }
          }

          &.split {
            ul {
              column-count: 2;
              -webkit-column-count: 2;
              -moz-column-count: 2;
              column-gap: 25px;

              li {
                break-inside: avoid;
                -webkit-column-break-inside: avoid;
                -moz-column-break-inside: avoid;
              }
            }
          }
        }
      }


      .whstapp-btn {
        display: flex;
        width: max-content;
        align-items: center;
        // background: #1D1D1B;
        // padding: 12px 20px;
        border-radius: 100%;
        cursor: pointer;

        img {
          display: inline-block;
          width: 35px;
          vertical-align: middle;
          // margin-right: 6px;
        }

        span {
          display: inline-block;
          vertical-align: middle;
          color: var(--white);
          line-height: 1;
          font-size: 12px;
        }
      }



    }

  }

  .eachlist-contact-sec.f-wrp {
    background: #F1F1F1;
    border: 0.5px solid #C6C6C6;
    border-top: none;
    border-radius: 0px 0px 10px 10px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 30px;

    ul {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
    }

    .contct-btn-sec {
      .whstapp-btn {
        display: flex !important;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        background: #FFFFFF;
        border: 1px solid #134870;
        border-radius: 10px;
        font-size: 13px;
        margin-left: 15px;
        cursor: pointer;
      }

      img {
        width: 20px;
        margin-right: 7px;
      }

      &.inquiry {
        .contact-btn {
          display: none;
        }
      }
    }
  }
}




.listing-detail-wrp {
  .breadcrumb {
    padding-bottom: 25px;

    .prop-label {
      margin-bottom: 0;
      font-size: 28px;
    }
  }

  .carousel-root.carousel-flex {
    .carousel.carousel-slider {
      height: 350px;

      img {
        height: 350px;
      }
    }
  }

  .detail-slider-wrp.f-wrp {
    padding-bottom: 40px;
  }





  .control-dots {
    display: none !important;
  }

  // .carousel-flex {
  //   display: flex;
  // }

  // .carousel.carousel-slider {
  //   flex: 3;
  // }

  // .carousel.carousel-slider+.carousel {
  //   flex: 1;

  //   .thumbs-wrapper.axis-vertical {
  //     transform: rotate(90deg);

  //     ul {
  //       li {
  //         // display: block !important;
  //         transform: rotate(90deg);
  //       }
  //     }
  //   }
  // }


  .carousel .thumb.selected,
  .carousel .thumb:hover {
    border: 3px solid transparent;
  }


  .each-block-sec {
    background: #FFFFFF;
    padding: 40px;
    border: 0.5px solid #EBDCA8;
    border-radius: 10px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    h4 {
      margin-top: 0;
    }

  }

  .property-detils {
    display: flex;
    justify-content: space-between;

    .prop-detail-wrp.f-wrp {
      max-width: 550px;
      // max-width: 418px;

      .mob-price {
        font-size: 24px;
        color: #134870;

        b {
          i {
            font-style: normal;
          }
        }
      }
    }

    .agent-detail-wrp.f-wrp {
      max-width: 165px;

      li {
        float: right;
      }

      .profile-blk {
        display: flex;
        align-items: center;

        h4 {
          margin-bottom: 0;
        }

        span {
          width: 60px;
          height: 60px;
          display: block;
          border-radius: 100%;
          overflow: hidden;
          margin-left: 10px;
        }
      }

      .contct-btn-sec {
        padding-top: 10px;

        .whstapp-btn {
          display: flex;
          white-space: nowrap;
          align-items: center;
          background: #1D1D1B;
          padding: 8px 20px;
          color: #fff;
          font-size: 14px;
          font-weight: 400;
          width: max-content;
          border-radius: 10px 0px 10px 10px;
          cursor: pointer;

          img {
            width: 16px;
            height: 16px;
            margin-right: 10px;
          }
        }
      }
    }
  }


  .flex-list.f-wrp {
    display: flex;
    justify-content: flex-start;
    // align-items: flex-end;
    align-items: flex-start;
    margin: 0;
    padding: 10px 0 20px;
    flex-wrap: wrap;

    &.property-detail-list,
    &.property-amenities-list {
      column-count: 2;
      display: block;
      list-style: disc;
      padding-left: 15px;

      @media only screen and (max-width: 767px) {
        &.property-detail-list {
          column-count: 1;
        }
      }
    }

    &.property-detail-list {
      b {
        text-transform: capitalize;
      }
    }

    li {
      max-width: 100%;
      margin-right: 30px;
      // min-width: 135px;
    }

    .list-icon {
      width: 20px;
      height: 20px;
      display: block;
      margin-top: 15px;

      svg {
        width: 100%;
        height: 20px;
      }
    }

    p {
      font-size: 14px;
      margin-top: 6px;
      line-height: 24px;
      margin-bottom: 0;
    }
  }

  .feature-list {

    label {
      font-weight: 700;
      font-size: 16px;
    }

    ul {
      list-style: disc;
      padding-left: 25px;
      margin-top: 10px;

      li {
        font-size: 14px;
        margin-bottom: 10px;
      }
    }

    &.split {
      ul {
        column-count: 2;
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-gap: 25px;

        li {
          break-inside: avoid;
          -webkit-column-break-inside: avoid;
          -moz-column-break-inside: avoid;
        }
      }
    }
  }

}

@media only screen and (max-width: 991px) {
  .listing-wrapper {
    .each-list-blk {
      flex-direction: column;

      .img-gallery-wrp {
        width: 100%;
        max-width: 100%;

        .gallery-slider-thumb,
        .carousel .thumbs-wrapper ul.thumbs {
          display: none;
        }

        span {
          display: none;

          img {
            border-radius: 10px 0 0 10px;
          }
        }

        span:nth-child(1) {
          display: block;
          height: auto;
        }
      }

      .list-cont-sec {
        padding: 20px 0 0;
        padding-left: 10px !important;

        label.prop-label {
          display: block !important;
          font-size: 16px !important;
          font-weight: 700;
          padding-bottom: 0 !important;

          span {
            display: block !important;
            padding-bottom: 10px;
          }

          b {
            display: none;
          }
        }

        .feature-list {
          display: none;
        }

        .contct-btn-sec {
          .whstapp-btn {
            border-radius: 10px 10px 10px 10px;
            text-align: center;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
            width: 100%;
          }
        }

        .prop-detail-wrp {
          .mob-price {
            display: flex;
            justify-content: space-between;

            .location {
              font-size: 14px !important;
              font-weight: 700 !important;

              img {
                width: 15px !important;
              }

              &+b {
                display: block;
                color: #134870;

                i {
                  font-size: 13px;
                  font-style: normal;
                }
              }
            }
          }

          .flex-list.f-wrp {
            align-items: flex-start !important;
            padding: 10px 0 0 !important;

            li {
              padding-right: 5px;
              display: flex;
              flex-direction: column;

              p {
                margin-bottom: 0;
                margin-top: 7px !important;

                b {
                  display: inline-block !important;
                }
              }
            }
          }
        }
      }
    }

    .eachlist-contact-sec.f-wrp ul {
      justify-content: center;
    }

    .detail-slider-wrp {
      .mySwiper2 {
        padding-right: 0px;
        padding-left: 0px;
        left: 0px;
        height: 300px;
      }

      .swiper-thumbs {
        display: none;
      }
    }




  }

  .mobRow {
    margin: 0 !important;
    width: 100% !important;

    .col-xs-12 {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }
}

.absolute-btn {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  cursor: pointer;
  z-index: 1;
}

.mySwiper2,
.mySwiperTwo {
  img {
    height: 410px;
  }

  height: 410px;
}


@media only screen and (max-width: 600px) {
  .listing-wrapper {
    padding-bottom: 80px;

    .breadcrumb a {
      display: none;
    }

    .list-main-wrapper.f-wrp {
      padding-top: 0px;

      .value-header {
        padding-top: 0px;

        h1 {
          font-size: 20px;
        }
      }
    }

    .each-list-blk {


      .list-cont-sec {

        // label.prop-label {

        //   // span {}
        // }

        // .feature-list,
        // .contct-btn-sec {}

        .prop-detail-wrp {

          .flex-list.f-wrp {

            li {
              flex-direction: row;

              span.list-icon {
                display: inline-block;
              }

              p {
                display: inline-block;
                margin-top: 0px !important;
                font-size: 12px !important;
                padding-left: 5px;

                b {
                  display: block !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .listing-detail-wrp {
    .detail-slider-wrp .mySwiper2 {
      height: 275px;
    }

    .property-detils.each-block-sec {
      flex-direction: column;
      padding: 15px;

      @media only screen and (max-width: 600px) {
        .prop-detail-wrp {
          .flex-list {
            li {
              p {
                display: flex;
                flex-wrap: wrap;
                padding-top: 15px;
                gap: 4px;

                .list-icon {
                  margin-top: 0;
                }

                b {
                  display: block;
                  width: 100%;
                }
              }
            }
          }
        }
      }

      .agent-detail-wrp {
        width: 100%;
        max-width: 100%;

        ul {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;

          li {


            .profile-blk {
              flex-direction: row-reverse;
              margin-right: 10px;

              span {
                margin-left: 0px;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }

  }

  .listing-detail-wrp .each-block-sec {
    padding: 15px;

  }

  .property-location-blk.feature-list.each-block-sec {
    .map-wrp {
      min-height: 230px;

      img {
        height: 100%;
      }
    }
  }

  .listing-wrapper .eachlist-contact-sec.f-wrp ul {

    // flex-direction: column;
    li:not(:last-child) {
      margin-right: 15px;
    }

    .contct-btn-sec .whstapp-btn {
      margin-left: 0;

      span,
      p {
        display: none;
      }

      img {
        margin: 0;
      }
    }
  }
}

.listing-detail-wrp {

  .swiper-button-next,
  .swiper-button-prev {
    background: #fff;
    border-radius: 100%;
    box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    height: 40px;
    width: 40px;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    font-size: 15px;
  }
}

.propertyList-gridView {
  width: calc(100% + 30px);
  margin-left: -15px;

  .eachGrid-box {
    flex-direction: column;
    padding: 0;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 30px;

    .slider-box.f-wrp {
      .gallery-slider.mySwiper2 {
        border-radius: 0;
        height: 200px;

        .swiper-button-next:after,
        .swiper-button-prev:after {
          font-size: 14px;
        }

      }

      .gallery-slider-thumb.swiper-thumbs {
        display: none;
      }

      img {
        height: 200px;
        object-fit: cover;
        object-position: center;
      }
    }

    .list-cont-sec.f-wrp {
      padding: 15px;

      .prop-detail-wrp {
        label.prop-label {
          font-size: 20px;
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;
          padding: 0;
          padding-bottom: 10px;
        }

        .location {
          font-size: 14px;
          align-items: center;

          img {
            width: 18px;
            margin-right: 3px;
          }
        }

        .flex-list.f-wrp {
          gap: 20px;
          padding: 0;
          padding-top: 15px;

          li {
            margin: 0;
          }

          p {
            margin-bottom: 0;
            font-size: 12px;
            line-height: 1.1;

            b {
              font-size: 11px;
            }
          }

          .list-icon {
            width: 18px;
            height: 18px;
            display: block;

            img,
            svg {
              width: 100%;
              height: 100%;
              object-fit: contain;
              object-position: center;
            }
          }
        }
      }
    }

    .eachlist-contact-sec.f-wrp {
      border: none;
      border-top: 0.5px solid #C6C6C6;
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 0;

      ul {
        justify-content: center;
        gap: 10px;
        flex-wrap: wrap;
        flex-direction: row;

        li {
          width: 100%;
          flex: 1;
          flex-shrink: 0;
        }
      }

      .contct-btn-sec {
        .whstapp-btn {
          padding: 7px 12px;
          font-size: 12px;
          margin-left: 0;
        }
      }
    }

  }
}

.listViewBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .list-view-switch {
    display: flex;
    gap: 10px;

    button {
      height: 30px;
      width: 30px;
      margin: 0;
      padding: 5px;
      border: 1px solid #134870;
      border-radius: 3px;
      background-color: var(--white);
      cursor: pointer;

      svg {
        width: 100%;
        height: 100%;
        --viewBtnClr: #134870;
      }

      &.active {
        background-color: #134870;

        svg {
          --viewBtnClr: #fff;
        }
      }
    }
  }
}


.sticky-contact-box {
  position: sticky;
  top: 85px;
  z-index: 100;
  background: #fff;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 10px 0px #e4e4e4;
  border: 0.5px solid #EBDCA8;
  padding: 15px;
  border-radius: 10px;

  span {
    margin: 0;
    font-size: 12px;
    text-transform: uppercase;
    padding-bottom: 5px;
    display: block;
  }

  .price {
    font-size: 20px;
    color: #134870;
    padding: 8px 0 12px;
    border-bottom: 1px solid #e4e4e4;
  }

  h4 {
    margin: 0;
    font-size: 16px;
    padding: 15px 0 10px;
  }

  p {
    margin: 0;
    font-size: 14px;
    padding-bottom: 5px;
    display: block;
  }

  .contct-btn-sec {
    padding-top: 15px;
    padding-bottom: 10px;
  }

  .whstapp-btn {
    display: flex;
    white-space: nowrap;
    align-items: center;
    background: #1D1D1B;
    padding: 12px 20px;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    border-radius: 10px 10px 10px 10px;
    cursor: pointer;
    gap: 10px;
    width: 100%;
    justify-content: center;

    img {
      width: 16px;
      height: 16px;
    }
  }

  .contct-btn-sec.inquiry {
    margin-top: 10px;
    padding-top: 20px;
    border-top: 1px solid #e4e4e4;
    padding-bottom: 0;

    .contact-btn {
      display: none;
    }

    .whstapp-btn {
      display: flex !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
      padding: 10px 20px;
      background: #FFFFFF;
      border: 1px solid #134870;
      border-radius: 10px;
      font-size: 13px;
      cursor: pointer;

      img {
        width: 24px;
        height: 100%;
        object-fit: contain;
        margin: 0;
      }

      p {
        padding-bottom: 0;
      }
    }
  }

}

@media only screen and (max-width: 991px) {
  .sticky-contact-box {
    margin-top: 30px;
  }
}

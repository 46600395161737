.blogListing-wrp {
  max-width: 991px;
  margin: 0 auto;
  float: none;
  display: flex;
  flex-direction: column;

  .swiper-slide {
    background: transparent;
  }

  .each-property-items {
    a {
      box-shadow: none;
      // padding: 0;
      border-radius: 0px 0 0 0;

      .propList-bg {
        border-radius: 30px;

        // set 2
        border-radius: 15px 15px 0 0;

        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          background: var(--shadow33);
        }

        .propLabel {
          padding: 5px 10px;
          border-radius: 0px 0px 10px 10px;
          height: 50px;
          width: 55px;
          text-align: center;
          color: var(--text2B);
          bottom: unset;
          top: 0;
          left: 50px;
          z-index: 5;
          display: flex;
          flex-direction: column;
          justify-content: center;
          line-height: 1.2;

          // set 2

          left: unset;
          right: 30px;
        }

        img {
          height: 185px;
        }
      }

      .propList-item-blk {
        height: calc(100% - 185px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        // set 1
        // background: #fff;
        // border-radius: 25px;
        // margin-top: 15px;
        // padding: 15px 25px;

        &.blog-itm-block{
          background: #fff;
          border-radius: 0px 0px 15px 15px;
          margin-top: 0;
          padding: 15px 0px 0px;
        }
        

        .img-btn {
          padding: 0;
          min-height: 10px;
          line-height: 0;
          background: transparent;
          border: none;
          float: right;
          display: flex;
          justify-content: flex-end;
          padding: 0px 25px 15px;
          margin: 0;


          svg {
            width: 40px;
            height: 40px;
          }
        }

        h1,
        p {
          
          color: var(--ThemeBG);
        }


        // set 1
        h1 {
          font-size: 20px;
        }

        // set 2
        .con-box {
          padding-left: 25px;
          padding-right: 25px;
          padding-bottom: 25px;
        }

      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    background-color: var(--white);

    svg {
      path {
        stroke: var(--Text);
      }
    }
  }

  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    top: var(--swiper-navigation-top-offset, 30%);
  }

  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    top: var(--swiper-navigation-top-offset, 30%);
  }

  .swiper-pagination-custom {

    span {
      width: 40px;
      height: 2px;
      border-radius: 2px;
      background-color: var(--white);
      border: none;
      box-shadow: none;
      opacity: 0.5;

      &.swiper-pagination-bullet-active {
        box-shadow: none;
        opacity: 1;
      }

      &.swiper-pagination-bullet-active-next-next,
      &.swiper-pagination-bullet-active-next,
      &.swiper-pagination-bullet-active-prev-prev,
      &.swiper-pagination-bullet-active-prev,
      &.swiper-pagination-bullet {
        transform: scale(1);
      }
    }
  }
}
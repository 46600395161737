.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 400px;
  width: 100%;
  border-radius: 10px;
  padding-right: 15px;
  padding-left: 15px;
  left: -10px;
}

.mySwiper {
  height: 400px;
  box-sizing: border-box;
  padding: 0;
}

.mySwiper img {
  border-radius: 10px;
}

.mySwiper .swiper-slide {
  width: 100%;
  height: 100%;
  opacity: 0.9;
  cursor: pointer;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after,
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  font-size: 15px;
}

.swiper-button-next,
.swiper-button-prev {
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.swiper-button-prev, .swiper-rtl .swiper-button-next {
  left: var(--swiper-navigation-sides-offset,3px);
  right: auto;
}
.swiper-button-next, .swiper-rtl .swiper-button-prev {
  right: var(--swiper-navigation-sides-offset,3px);
  left: auto;
}
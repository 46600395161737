@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --Text: #1A1440;
    --ThemeBG: #1A1440;
    --BG880: #FEF8F880;
    --textD4: #D4D4D4;
    --text80: #808080;
    --text2B: #2B2B2B;
    --textBlue: #4361EE;
    --shadow40: #00000040;
    --shadow33: #00000033;
    --bgD9: #D9D9D9;

}

.header-revamp-wrp {
    background-color: var(--white);

    * {
        font-family: "Poppins", sans-serif;
    }

    @media (min-width: 1400px) {
        .container {
            max-width: 1379px;
        }
    }

    @media (min-width: 1200px) {
        .container {
            max-width: 1175px;
        }
    }

    @media (min-width: 992px) {
        .container {
            max-width: 1150px;
        }
    }

    .header-info-ribbon {
        padding: 10px 0;

        ul {
            list-style-type: none;
            padding: 0;
            display: flex;
            justify-content: space-between;
            width: 100%;
            gap: 25px;
            margin: 0;

            li {
                font-family: Poppins;
                font-size: 13.3px;
                font-weight: 400;
                line-height: 22px;
                text-align: left;
                display: flex;
                gap: 6px;
                align-items: center;
                color: var(--Text);

                a {
                    display: flex;
                    gap: 6px;
                    color: inherit;
                    font-size: inherit;
                    font-weight: inherit;
                }

                .MuiSvgIcon-root {
                    width: 18px;
                    height: 18px;

                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }

                &:first-child {
                    margin-right: auto;

                    @media only screen and (max-width: 767px) {
                        & {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    &.MuiAppBar-positionSticky {
        background-color: var(--ThemeBG);
        padding: 5px 0;

        .container {
            width: 100%;
        }
    }

    .landing-navbar {
        .MuiToolbar-root {
            padding-left: 0;
            padding-right: 0;
            justify-content: space-between;
            width: 100%;

            .landing-logo {
                a {
                    width: 165px;
                    display: block;
                }
            }

            .nav-list {
                display: flex;
                align-items: center;
                // gap: 17px;
                gap: 0;
                width: 100%;

                .f-ww {
                    width: 100%;
                    display: flex;
                    gap: 17px;
                    justify-content: center;
                }

                .f-wr {
                    display: flex;
                    flex-shrink: 0;
                    gap: 17px;
                }

                @media only screen and (max-width: 1200px) {
                    & {
                        gap: 13px;
                    }
                }

                a {
                    font-size: 16px;
                    font-weight: 500;
                    text-align: left;
                    color: var(--white);

                    @media only screen and (max-width: 1200px) {
                        & {
                            font-size: 14px;
                        }
                    }

                    &.head-btn {
                        padding: 10px 25px;
                        border: 1px solid var(--white);
                        display: flex;
                        gap: 8px;
                        border-radius: 50px;

                        @media only screen and (max-width: 1200px) {
                            & {
                                padding: 8px 20px;
                            }
                        }

                        .MuiSvgIcon-root {
                            height: 20px;
                            width: 20px;

                            svg {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }

                .contact-btn {
                    display: none;
                }

                .nav-btn {
                    display: flex !important;
                }

                .head-btn-box {
                    .nav-btn {
                        display: none !important;
                    }
                }
            }
        }

        @media only screen and (max-width: 992px) {
            & {
                display: none;
            }
        }

    }

    .mob-navBar {
        display: none;
        width: 100%;
        justify-content: space-between;

        * {
            font-family: "Poppins", sans-serif;
        }


        .landing-logo {
            a {
                width: 165px;
                display: block;
            }
        }

        .mob-icon {
            margin: 0;
            padding: 0;
        }

        @media only screen and (max-width: 992px) {
            & {
                display: flex;
            }
        }
    }


}

.mob-draw-wrp-revamp {
    * {
        font-family: "Poppins", sans-serif;
    }

    .MuiPaper-root.MuiPaper-elevation {
        background-color: #1a1440d6;
        display: block;
    }

    .mob-drawer-revamp {
        padding: 15px;
        // height: calc(100% - 150px);
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .profile-info-wrp {
            display: flex;
            gap: 15px;
            span {
                width: 55px;
                height: 55px;
                display: block;
                border: 1px solid #fff;
                padding: 7px;
                border-radius: 100%;
                flex-shrink: 0;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    object-position: center;
                }
            }

            .detail-wrp {
                text-align: left;
                // padding-left: 15px;

                h3 {
                    margin: 0;
                    font-size: 14px;
                    font-weight: 500;
                    color: var(--white);
                }

                p {
                    color: var(--white);
                    margin: 0;
                    font-weight: 300;
                    font-size: 12px;
                }
            }
        }

        .close-btn {
            width: 100%;
            color: #fff;
        }

        .nav-list {
            display: flex;
            width: 100%;
            flex-direction: column;
            gap: 10px;
            height: 100%;
            justify-content: space-between;
            padding-top: 15px;

            a {
                font-size: 16px;
                font-weight: 500;
                text-align: left;
                color: var(--white);
                border-bottom: 1px solid #ffffff52;
                padding-bottom: 10px;

                .MuiButtonBase-root {
                    text-align: left;
                }

                &.head-btn {
                    padding: 8px 20px;
                    border: 1px solid var(--white) !important;
                    display: flex;
                    gap: 8px;
                    border-radius: 50px;
                    justify-content: center;

                    .MuiSvgIcon-root {
                        height: 20px;
                        width: 20px;

                        svg {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }

            .link-btn,
            .box-btn {
                display: flex;
                flex-direction: column;
                gap: 10px;
            }

            .box-btn {
                gap: 20px;

                .head-btn-box {
                    .contact-btn {
                        display: none !important;
                    }
                }
            }

        }

        .landing-logo {
            display: flex;
            align-items: center;
            padding-bottom: 20px;
            justify-content: space-between;


            a {
                width: 100%;
                max-width: 155px;
                height: 55px;
                display: block;

                img {
                    height: 100%;
                    object-fit: contain;
                    position: relative;
                    left: -15px;
                }

            }

            .close-btn {
                width: max-content;
            }
        }
    }
}



.home-main-wrp {
    background-color: var(--white);

    * {
        font-family: "Poppins", sans-serif;
    }

    .banner-wrp {
        min-height: calc(100vh - 120px);
        padding: 55px 0;

        .bannerBg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        .banner-content {
            min-height: calc(100vh - 230px);
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @media only screen and (max-width: 768px) {
                & {
                    justify-content: center;
                }
            }

            h1 {
                font-size: 51.54px;
                font-weight: 600;
                text-align: left;
                color: var(--Text);
                margin: 0;
                max-width: 590px;

                @media only screen and (max-width: 768px) {
                    & {
                        font-size: 40.54px;
                        color: var(--white);
                        text-shadow: 0px 4px 4px #000000BA;
                        text-align: center;
                    }
                }
            }

            .filter-box {
                display: flex;
                backdrop-filter: blur(10px);
                box-shadow: 0px 4px 4px 0px #00000040;
                border-radius: 30px 0 30px 0;
                overflow: hidden;
                padding: 15px 25px;
                margin-top: 100px;

                @media only screen and (max-width: 992px) {
                    & {
                        flex-direction: column-reverse;
                        margin-top: 50px;
                    }
                }

                @media only screen and (max-width: 500px) {
                    & {
                        padding: 12px;
                    }
                }

                h4 {
                    font-family: Poppins;
                    font-size: 32.18px;
                    font-weight: 500;
                    margin: 0;
                    text-align: center;
                    width: 145px;
                    line-height: 1;
                    padding-bottom: 17px;

                    @media only screen and (max-width: 992px) {
                        & {
                            padding-top: 25px;
                            width: 100%;
                        }
                    }
                }

                .filter-head {
                    display: none;

                    @media only screen and (min-width: 900px) {
                        & {
                            display: block;
                        }
                    }
                }

                .search-btn {
                    background: var(--Text);
                    color: var(--white);
                    width: 100%;
                    gap: 10px;
                    border-radius: 50px;
                    padding: 8px;

                    @media only screen and (max-width: 500px) {
                        & {
                            font-size: 14px;
                            gap: 7px;
                        }
                    }

                    .MuiSvgIcon-root {
                        width: 20px;
                        height: 20px;

                        @media only screen and (max-width: 500px) {
                            & {
                                width: 15px;
                                height: 15px;
                            }
                        }

                        svg {
                            width: 100%;
                            height: 100%;
                        }
                    }

                }

                .filter-form {
                    padding-left: 25px;

                    @media only screen and (max-width: 992px) {
                        & {
                            padding-left: 0px;
                        }
                    }
                }
            }
        }
    }

    .each-home-sec {
        padding: 75px 0 75px;

        .section-header {
            text-align: center;
            padding-bottom: 35px;
            max-width: 991px;
            margin: 0 auto;
            float: none;

            h1 {
                color: var(--Text);
                font-size: 39.8px;
                font-weight: 600;
                text-align: center;
                margin: 0;
            }

            p {
                margin: 0;
                color: var(--text2B);
                font-family: Poppins;
                font-size: 19.4px;
                font-weight: 500;
                letter-spacing: 0.09em;
                text-align: center;
                text-transform: capitalize;
            }
        }

        &.property-listing-wrp {
            .section-header {
                padding-bottom: 15px;
            }

            .item-listing-wrp {
                padding-bottom: 45px;

                &+.custom-pln-btn {
                    color: var(--white);
                    background-color: var(--Text);
                    border: 1px solid var(--Text);
                    cursor: pointer;

                    &:hover {
                        color: var(--Text);
                        border: 1px solid var(--Text);
                        background-color: var(--white);
                    }
                }
            }
        }

        &.blog-listing-wrp {
            background: var(--ThemeBG);

            .section-header {
                padding-bottom: 50px;

                h1,
                p {
                    color: var(--white);
                }
            }
        }

        &.service-listing-wrp {
            background: url('../../assets/images/HomePage/serviceBG.png');
            background-size: 100% 100%;

            .section-header {
                max-width: 100%;

                h1,
                p {
                    text-align: left;
                }
            }
        }

        &.review-listing-wrp {
            .section-header {

                h1,
                p {
                    color: var(--text2B);
                }
            }
        }

        &.team-listing-wrp {
            padding-top: 45px;
            padding-bottom: 35px;
            .section-header {
                p {
                    color: var(--textBlue);
                    text-transform: uppercase;
                }

                h1 {
                    color: var(--text2B);
                }
            }
        }

        &.agent-required-wrp {
            padding-bottom: 150px;
            padding-top: 115px;
            @media only screen and (max-width: 992px) {
                & {
                    padding-bottom: 50px;
                    padding-top: 10px;
                }
            }

            .agent-required-box {
                display: flex;
                background-color: var(--Text);
                background-image: url('../../assets/images/HomePage/agentBG.png');
                background-size: 100% 100%;
                object-position: center;
                border-radius: 30px 0 30px 0;

                @media only screen and (max-width: 768px) {
                    & {
                        background-size: cover;
                    }
                }

                .img-blk {
                    width: 375px;

                    @media only screen and (max-width: 992px) {
                        & {
                            width: 250px;
                        }
                    }

                    @media only screen and (max-width: 768px) {
                        & {
                            display: none;
                        }
                    }

                    // flex: 1;
                    span {
                        display: block;
                        height: 100%;
                        position: relative;
                        width: 100%;
                    }

                    img {
                        width: calc(100% + 40px);
                        height: calc(100% + 40px);
                        left: 0;
                        top: -40px;
                        object-fit: contain;
                        object-position: bottom left;
                        position: absolute;
                    }
                }

                .content-sec {
                    display: flex;
                    // flex: 2.5;
                    width: calc(100% - 330px);
                    padding-top: 50px;
                    padding-bottom: 45px;
                    padding-right: 55px;
                    gap: 50px;
                    padding-left: 15px;

                    @media only screen and (max-width: 1200px) {
                        & {
                            flex-direction: column;
                            padding: 25px 15px;
                            gap: 25px;
                        }
                    }

                    @media only screen and (max-width: 992px) {
                        & {
                            width: calc(100% - 250px);
                        }
                    }

                    @media only screen and (max-width: 768px) {
                        & {
                            width: 100%;
                            padding: 25px 30px;
                        }
                    }

                    .content-box {

                        h1,
                        p {
                            color: var(--white);
                            margin: 0;
                        }

                        h1 {
                            font-weight: 600;
                            font-size: 34px;
                            padding-bottom: 10px;

                            @media only screen and (max-width: 1200px) {
                                & {
                                    font-size: 26px;
                                }
                            }
                        }

                        p {
                            font-size: 16px;

                            @media only screen and (max-width: 1200px) {
                                & {
                                    font-size: 12px;
                                }
                            }
                        }
                    }

                    .reg-btn {
                        display: inline-block;
                        width: fit-content;
                        white-space: nowrap;
                        padding: 14px 40px;
                        height: fit-content;
                        font-weight: 500;
                        font-size: 20px;
                        border-radius: 50px;
                        background: var(--white);
                        margin-top: 20px;

                        @media only screen and (max-width: 1200px) {
                            & {
                                padding: 7px 25px;
                                font-size: 14px;
                                margin-top: 0px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.custom-pln-btn {
    color: var(--Text);
    border: 1px solid var(--Text);
    background-color: var(--white);
    padding: 10px 30px;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    margin: 0 auto;
    display: table;
    margin-top: 15px;
    transition: 0.5s all;

    &:hover {
        transition: 0.5s all;
    }
}

#logout-modal {
    .MuiPaper-root {

        border-radius: 15px;
    }

    .MuiDialogContent-root {
        padding: 0;
        padding-top: 30px;
        border-radius: 25px;

        .logout-box {
            h1 {
                font-size: 18px;
                margin: 0;
                text-align: center;
                padding-bottom: 10px;
            }

            p {
                margin: 0;
                text-align: center;
                font-size: 14px;
                padding-top: 5px;
            }
        }

        .btn-grid {
            display: flex;
            margin-top: 25px;
            border-top: 1px solid #e4e4e4;
            gap: 1px;
            background: #e4e4e4;

            button {
                flex: 1;
                width: 100%;
                background: #fff;
                border: none;
                height: 50px;
                font-weight: 700;
                color: #000;
                font-size: 16px;
                cursor: pointer;
            }

            &.comment-btns{
                border-top: none;
                background: transparent;
                margin-bottom: 25px;
                margin-top: 15px;
                justify-content: center;
                gap: 15px;
                button{
                    height: 30px;
                    font-weight: 500;
                    width: 70px;
                    font-size: 14px;
                    flex: none;
                }
            }
        }
    }

}
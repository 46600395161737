* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@font-face {
  font-family: 'Microsoft JhengHei';
  src: url('./assets/css/fonts/microsoft_jhenghei.woff') format('woff');
}

/* @font-face {
  font-family: 'Square Peg', cursive;
  src: url('https://fonts.googleapis.com/css2?family=Square+Peg&display=swap');
} */


:root {
  --bodyBG: #f8f8f8;
  --transparent: #ffffff00;
  --nav-text: #1D1D1B;
  --text: #1D1D1B;



  --primary: #4c2aa6;
  --accent: #a667fc;
  --secondary: #542d76;
  --neutral: #aeacac66;
  --modal: #00000066;
  --danger-400: #c8522c;
  --danger: #c23f15;
  --warning-300: #fbedbb;
  --warning-400: #ffdd66;
  --warning: #ff9e01;
  --warning-600: #a07e00;
  --black: #101010;
  --white: #fff;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Microsoft JhengHei' !important;
}

.f-wrp {
  position: relative;
  width: 100%;
  float: left;
  display: block;
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

p {
  color: var(--nav-text);
}

.project-main-header {
  padding: 15px 5px;
  height: 84px;
  background-color: #1D1D1B;
}

.main-nav-wrp ul {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
}

.main-nav-wrp ul li,
.main-nav-wrp ul button {
  text-transform: capitalize;
  color: var(--nav-text);
  font-size: 15px;
}

.custom-header {
  max-width: 1320px;
  align-items: center;
  margin: 0 auto;
  width: 100%;
}

.project-main-footer {
  padding: 55px 0 15px;
  box-shadow: 0px 0px 5px 3px #e4e4e4;
}

.project-main-header.main-header.scrolled,
.main-header.scrolled .custom-header,
.main-header.scrolled .logo-wrp img,
.main-header.scrolled .custom-navbar,
.main-header.scrolled .custom-navbar .nav-link-btn,
.project-main-header.main-header,
.main-header .custom-header,
.main-header .logo-wrp img,
.main-header .custom-navbar,
.main-header .custom-navbar .nav-link-btn {
  transition: 0.5s all;
}

.project-main-header.main-header.scrolled {
  height: 55px;
}

.main-header.scrolled .custom-header {
  min-height: 50px;
}

.main-header.scrolled .logo-wrp img {
  width: 110px;
}

.main-header.scrolled .custom-navbar {
  padding-top: 2px;
  padding-bottom: 2px;
}

.main-header.scrolled .custom-navbar .nav-link-btn {
  font-size: 12px;
  font-weight: 600;
}

.custom-navbar {
  background-color: #1D1D1B !important;
  box-shadow: 0px 0px 1px 0px #1D1D1B !important;
}

.each-foot-link ul li {
  cursor: pointer;
  font-size: 14px;
  text-transform: capitalize;
  color: var(--white);
}

.each-foot-link ul li a {
  color: #ffffff;
}
.each-foot-link ul li:hover,
.each-foot-link ul li a:hover {
  color: #EBDCA8;
}

.foot-sub-head {
  padding-left: 16px;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  color: var(--white);
}

.copy-txt {
  text-align: left;
  font-size: 12px;
}
.foot-cpy-sec{
  padding-top: 60px;
}
.foot-cpy-sec .row{
  align-items: center;
}
.foot-cpy-sec ul{
  /* margin: 0; */
}
.copy-txt p {
  color: var(--white);
  margin: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.container,
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  padding-left: -15px;
  padding-right: -15px;
  width: 100%;
}

.row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;

}

.yarl__thumbnails_thumbnail {
  width: var(--yarl__thumbnails_thumbnail_width, 80px) !important;
  height: var(--yarl__thumbnails_thumbnail_height, 40px) !important;
  background: var(--yarl__thumbnails_thumbnail_background, transparent) !important;
}
.yarl__container{
  background-color: #00000099 !important;
}
.yarl__thumbnails_container {
  background-color: var(--yarl__thumbnails_container_background_color, var(--yarl__color_backdrop, rgba(0, 0, 0, .8))) !important;
}

.yarl__thumbnails_vignette {
  /* background: rgba(0, 0, 0, .8) !important; */
  --yarl__thumbnails_vignette_size: 0% !important;
}

.yarl__slide_captions_container {
  background: var(--yarl__slide_captions_container_background, rgba(0, 0, 0, 0.5)) !important;
  display: none !important;
}

.yarl__slide_captions_container div {
  text-align: center;
}

.yarl__container.yarl__flex_center {
  flex-direction: column-reverse;
}

.yarl__toolbar {
  position: relative !important;
  width: 100%;
  /* top: 25px !important; */
}

.yarl__carousel {
  height: calc(100% - 50px) !important;
}
.yarl__fullsize img{
  width: auto;
  max-height: 95%;
  max-width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  touch-action: var(--yarl__controller_touch_action, none);
  -moz-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1080px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1180px;
  }
}


@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}
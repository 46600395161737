.privacy-main-wrapper {
  background: #fff;
  padding: 15px 0 115px;

  .abt-img-wrp {
    span {
      max-width: 225px;
      display: block;
      margin: 0 auto;
      margin-bottom: 20px;
    }
  }

  .abt-main-header {
    font-weight: 400;
    font-size: 26px;
    margin: 0 auto;
    margin-bottom: 50px;
    text-align: center;
    text-transform: uppercase;
    line-height: 1.8;
  }







  .abt-cont-wrp {
    max-width: 1080px;
    margin: 0 auto;
    float: none;
    display: table;

    .abt-main-header {
      font-weight: 400;
      font-size: 26px;
      margin: 0 auto;
      margin-bottom: 50px;
      text-align: center;
      // max-width: 765px;
      text-transform: uppercase;
      line-height: 1.8;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      margin: 0 auto;
      // margin-bottom: 0px;
    }

    label {
      font-weight: 400;
      font-size: 18px;
      display: block;
      margin: 0 auto;
      text-transform: uppercase;
      margin-bottom: 20px;
      letter-spacing: 2px;
    }

    ul {
      margin: 0;
      list-style: disc;
      padding-left: 25px;

      li {
        margin-bottom: 6px;
        b {
          font-weight: 400;
          font-size: 16px;
          display: block;
          margin: 0 auto;
          text-transform: uppercase;
          margin-bottom: 10px;
          letter-spacing: 2px;
        }
      }
      ul{
        list-style: circle;
        padding-left: 30px;
      }
    }






  }





}
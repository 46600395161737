.inside-fractional-wrp {
  &.project-main-header {
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
  }

  &.main-header {
    .custom-navbar{
      padding-top: 10px;
      background-color: transparent !important;
    }
    .custom-header {
      max-width: 1200px;
      .MuiTypography-root {
        & {
          .logo-wrp {
            display: none;
          }
        }
      }

      .logo-wrp {

        span {
          svg {
            path {
              stroke: #134870 !important;
            }
          }
        }
      }
    }

    &.scrolled {
      .custom-navbar{
        background-color: transparent !important;
      }
      .custom-header {
        background-color: transparent;
      }
    }
  }

  &+.content+.project-main-footer {
    box-shadow: none;
  }



}

.fractional-main-wrp {
  max-width: 2500px;
  margin: 0 auto;
  float: none;
  display: flex;
  flex-direction: column;
  gap: 30px;
  @media only screen and (max-width: 767px) {
    & {
      gap: 0px;
    }
  }

  .each-fractional-wrp {
    padding-top: 75px;
    // padding-bottom: 120px;
    @media only screen and (max-width: 767px) {
      & {
        padding-top: 15px;
      }
    }

    .fraction-bg-img {
      position: absolute;
      height: 100%;
      width: 50%;
      right: 0;
      top: 0;

      @media only screen and (max-width: 767px) {
        & {
          display: none;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: right;
      }
    }

    .each-fractional-sec {
      width: 50%;
      padding-right: 5%;
      // padding-top: 75px;
      padding-bottom: 120px;
      @media only screen and (max-width: 767px) {
        & {
          width: 100%;
          padding-right: 0;
          padding-bottom: 20px;
        }
      }

      .fractional-head {
        display: flex;
        gap: 5px;
        align-items: center;

        span {
          display: block;
          width: 35px;

          img {
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }

        h3 {
          margin: 0;
          font-size: 22px;
          color: #1D1D1B;
        }

      }

      .fractional-con {
        p {
          font-size: 18px;
          max-width: 435px;
        }
      }
    }

    &:nth-child(even) {
      .fraction-bg-img {
        left: 0;
        bottom: 0;

        img {
          object-position: left;
        }
      }

      .each-fractional-sec {
        padding-left: 5%;
        padding-right: 0;
        float: right;
        @media only screen and (max-width: 767px) {
          & {
            padding-left: 0;
          }
        }
      }
    }

    .eligible-btn-box {
      // background: rgba(19, 72, 112, 0.50);
      // backdrop-filter: blur(8px);
      padding: 0px 0px 50px;

      .eligible-btn {
        display: block;
        padding: 12px 100px;
        color: #fff;
        border-radius: 50px;
        background: #134870;
        backdrop-filter: blur(2px);
        float: right;
        width: 100%;
        max-width: 435px;
        text-align: center;
        // margin-right: 25px;
        @media only screen and (max-width: 767px) {
          & {
            float: none;
            margin: 0 auto;
            margin-top: 20px;
            margin-bottom: 20px;
          }
        }
      }
    }

  }
}

.theme-page-switch {
  width: max-content;
  position: relative;
  z-index: 9;

  h3 {
    margin: 0;
    color: #1D1D1B;
    font-size: 32px;
  }

  ul {
    display: flex;
    background: #F1F1F1;
    padding: 5px;
    margin-bottom: 0;
    border-radius: 50px;

    li {
      span {
        display: inline-block;
        padding: 10px 30px;
        background: #134870;
        border-radius: 50px;
        color: #fff;
      }

      a {
        display: inline-block;
        padding: 10px 30px;
        background: #F1F1F1;
        border-radius: 50px;
        color: #134870;
      }
    }
  }
}

.social-wrp.f-wrp {
  display: flex;
  max-width: 435px;
  float: right;
  clear: both;
  align-items: center;
  justify-content: center;
  gap: 10px;
  @media only screen and (max-width: 767px) {
    & {
      float: none;
      margin: 0 auto;
    }
  }
  ul{
    display: flex;
    gap: 5px;
    li{
      a{
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 100%;
          // &.telegram{

          // }
        &.whatsapp{
          background: #01ad5c;
          padding: 3px;
        }
      }
    }
  }
}
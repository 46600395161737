.item-listing-wrp {
  .property-slider {
    height: auto;
  }

  .filter-btns {
    display: flex;
    justify-content: center;
    max-width: 500px;
    align-items: center;
    gap: 20px;
    margin: 0 auto;
    float: none;
    padding-bottom: 30px;

    .pln-btn {
      color: var(--Text);
      border: 1px solid var(--Text);
      background-color: var(--white);
      padding: 7px 25px;
      border-radius: 50px;
      font-size: 14px;
      font-weight: 500;
      text-align: center;

      &.active,
      &:hover {
        background-color: var(--Text);
        color: var(--white);
      }
    }
  }


  .each-property-items {
    padding: 10px;
    height: 100%;

    a {
      display: block;
      width: 100%;
      height: 100%;
      float: left;
      box-shadow: 0px 4px 6.1px 2px var(--shadow40);
      padding: 15px;
      border-radius: 50px 0 0 0;

      .propList-bg {
        border-radius: 40px 0 40px 0;
        overflow: hidden;
        position: relative;

        img {
          height: 315px;
          width: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 0;
        }

        .propLabel {
          position: absolute;
          left: 25px;
          bottom: 25px;
          background-color: #e4e4e4;
          color: #333;
          padding: 5px 20px;
          border-radius: 50px;
          font-size: 14px;
          font-weight: 500;

          &.discount-price {
            font-size: 12px;
            display: flex;
            gap: 3px;
            padding: 6px 15px;
            left: 7px;
            background: #F1FFF1;
            color: #00CE3A;

            svg {
              width: 15px;
              height: 18px;
            }
          }

          &.newList {
            font-size: 12px;
            display: flex;
            gap: 3px;
            padding: 6px 15px;
            left: unset;
            right: 7px;
            background: #D7EEFF;
            color: #119BFF;

            svg {
              width: 15px;
              height: 15px;
            }
          }
        }

        .labels {
          position: absolute;
          left: 0;
          bottom: 25px;
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding: 0px 7px;
          gap: 10px;

          .propLabel {
            position: unset;

            &.discount-price {
              font-size: 12px;
              display: flex;
              gap: 3px;
              padding: 6px 15px;
              left: 7px;
              background: #F1FFF1;
              color: #00CE3A;

              svg {
                width: 15px;
                height: 18px;
              }
            }

            &.newList {
              font-size: 12px;
              display: flex;
              gap: 3px;
              padding: 6px 15px;
              left: unset;
              right: 7px;
              background: #D7EEFF;
              color: #119BFF;

              svg {
                width: 15px;
                height: 15px;
              }
            }
          }
        }
      }

      .propList-item-blk {
        text-align: left;
        padding-top: 20px;

        h1 {
          font-size: 22px;
          font-weight: 600;
          text-align: left;
          margin: 0;
          color: var(--text2B);
        }

        p {
          font-size: 14px;
          font-weight: 400;
          text-align: left;
          color: var(--text2B);
          margin: 0;

          b {
            font-size: 16px;
            font-weight: 500 !important;
            padding: 4px 0;
            display: block;
          }
        }

        .detail-list {
          display: flex;
          justify-content: flex-start;
          gap: 20px;

          span {
            font-size: 14px;
            font-weight: 400;
            text-align: left;
            color: var(--text2B);
            gap: 5px;
            display: flex;

            svg {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    background-color: var(--Text);
    width: 40px;
    height: 40px;
    border: none;

    svg {
      width: 15px;
      height: 15px;
    }
  }

  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: var(--swiper-navigation-sides-offset, -20px);

    &::after {
      display: none;
    }
  }

  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: var(--swiper-navigation-sides-offset, -20px);

    &::after {
      display: none;
    }
  }

  @media only screen and (max-width: 768px) {

    .swiper-button-prev,
    .swiper-rtl .swiper-button-next {
      left: var(--swiper-navigation-sides-offset, -10px) !important;
    }

    .swiper-button-next,
    .swiper-rtl .swiper-button-prev {
      right: var(--swiper-navigation-sides-offset, -10px) !important;
    }
  }

  .swiper,
  .swiper-wrapper,
  .swiper-slide {
    height: unset !important;
  }

  .swiper-pagination-custom {
    position: relative;

    span {
      width: 15px;
      height: 15px;
      display: inline-block;
      border-radius: 100%;
      background-color: var(--Text);
      border: none;
      box-shadow: 0px 0px 20px 6px var(--bgD9) inset;
      opacity: 1;

      &.swiper-pagination-bullet-active {
        box-shadow: 0px 0px 0px 4px var(--bgD9) inset;
      }

      // &.swiper-pagination-bullet-active-next-next,
      // &.swiper-pagination-bullet-active-next,
      // &.swiper-pagination-bullet-active-prev-prev,
      // &.swiper-pagination-bullet-active-prev {
      //   transform: scale(1);
      // }
    }
  }
}

.property-loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .property-listing-wrp & {
    height: 450px;
  }

  svg {
    width: 70px;
    height: 70px;
  }
}

@media only screen and (max-width: 767px) {}
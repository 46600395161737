body {
    background: var(--bodyBG);
    color: var(--black);
    font-weight: normal;
}

a {
    text-decoration: none !important;
}

ul {
    padding: 0;
    list-style: none;
}

:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
}

.project-main-header .row {
    align-items: center;
}

#composition-menu {
    display: block;
}

.each-sec-wrapper {
    padding: 45px 0;
    text-align: center;
}

.logo-wrp span {
    display: block;
    line-height: 0;
}

.foot-logo-wrp .logo-wrp .foottag {
    margin-top: 15px;
}

.foot-sub-head {
    padding-bottom: 5px;
    display: block;
}

.custom-navbar {
    padding-top: 10px;
    padding-bottom: 10px;
}

.custom-navbar .nav-link-btn {
    color: var(--white) !important;
    margin-left: 20px;
    font-weight: 500;
    transition: 0.5s all ease;
}

.custom-navbar .nav-link-btn:hover {
    font-weight: 700;
    transition: 0.5s all ease;
    background-color: unset;
    color: #EBDCA8 !important;
}

.banner-bg.f-wrp {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.banner-bg.f-wrp img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.banner-main-content {
    text-align: center;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.56) 0%, rgba(0, 0, 0, 0.75) 51.5%, rgba(0, 0, 0, 0.31) 100%);
    backdrop-filter: blur(7px);
}

.banner-counter {
    color: #fff;
    display: flex;
    width: 100%;
    border-radius: 5px;
    padding-bottom: 10px;
    flex-direction: column;
    align-items: center;
}

.banner-counter h3 {
    color: #EBDCA8;
    text-align: center;
    font-family: "Microsoft JhengHei";
    font-size: 24px;
    line-height: 1;
    font-weight: 700;

}

.banner-counter p {
    color: #fff;
    font-size: 14px;
    margin-top: 7px;
    margin-bottom: 0;
    font-family: 'Microsoft JhengHei' !important;
    font-weight: 500;
}
@media only screen and (max-width: 600px) {
    .banner-counter p {
        margin-bottom: 20px;
    }
}

.banner-counter .countBoxItem {
    display: flex;
    flex-direction: column-reverse;
}

.banner-counter .countBoxItem .label {
    font-family: 'Microsoft JhengHei' !important;
    font-weight: 500;
    margin-top: 5px;
}

.banner-counter .countBoxItem .count {
    font-size: 28px;
    width: 65px;
    height: 62px;
    line-height: 62px;
    border-radius: 4px;
    font-family: 'Microsoft JhengHei' !important;
    font-weight: bold;
}

.banner-main-content .banner-counter .displayedTime {
    display: flex;
    justify-content: flex-end;
    position: relative;
    /* top: 20px; */
}

.banner-main-content .banner-counter .displayedTime .countBox {
    display: flex;
    flex-direction: row-reverse;
    color: #fff;
    border-radius: 5px;
    gap: 25px;
    font-size: 12px;
    font-weight: 400;
}

.banner-main-content .contnt-wrp {
    min-height: calc(100vh - 84px);
    padding-top: 25px;
    padding-bottom: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.banner-main-content .contnt-wrp h1 {
    font-weight: normal;
    color: var(--white);
    max-width: 800px;
}

.banner-main-content .contnt-wrp h1 b {
    display: inline-block;
}

.banner-btn {
    display: inline-block;
    background: var(--white);
    color: var(--text) !important;
    padding: 12px 40px;
    font-size: 13px !important;
    font-weight: bold !important;
    text-decoration: none !important;
    border-radius: 10px;
    margin-top: 25px !important;
}

.listing-main-wrapp.f-wrp {
    padding: 75px 0 90px;
}

.list-header {
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.list-header ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    padding-left: 0;
    gap: 20px;
    flex-wrap: wrap;
}

.list-header label {
    font-size: 22px;
    font-weight: 700;
}

.list-header li a {
    color: #134870;
    font-size: 16px;
    font-weight: 700;
}

.value-main-wrpp.f-wrp {
    background: #F1F1F1;
    padding: 50px 0 50px;
}

.value-main-wrpp .each-blk-sec {
    padding-bottom: 30px;
}

.value-main-wrpp .each-abt-blk {
    display: flex;
    background: #fff;
    padding: 25px;
    border-radius: 10px;
    border: 0.5px solid #C6C6C6;
    /* margin-bottom: 30px; */
    flex-direction: column;
    height: 100%;
    align-items: center;
    max-width: 225px;
    text-align: center;
}

.value-main-wrpp .flextec .each-abt-blk {
    flex-direction: row;
}

.value-main-wrpp .flextec .each-abt-blk .icon-blk span {
    width: 70px;
}

.each-abt-blk .icon-blk {
    padding-bottom: 25px;
}

.value-main-wrpp .flextec .each-abt-blk .icon-blk {
    padding-bottom: 0px;
}

.each-abt-blk .icon-blk span {
    width: 70px;
    display: block;
}

.value-main-wrpp .flextec .each-abt-blk .cont-blk {
    padding-left: 25px;
}

.cont-blk label {
    font-weight: 700;
    color: #134870;
    font-size: 18px;
}

.cont-blk p {
    font-size: 12px;
    font-weight: 400;
}

.value-header.f-wrp {
    padding-bottom: 20px;
}

.value-header.f-wrp h1 {
    padding-left: 15px;
    font-size: 28px;
    font-weight: 700;
}

/* .partner-list.f-wrp{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
} */
.partner-list.f-wrp li {
    padding: 0;
    margin-bottom: 15px;
    display: block;
    float: left;
    width: 20%;
    min-width: 150px;
}

.partner-list.f-wrp li:not(:last-child) {
    margin-right: 15px;
}

.partner-list.f-wrp li .icon-blk {
    float: left;
    width: 25%;
    max-width: 170px;
    min-width: 170px;
    height: 150px;
    padding: 10px;
}

.partner-list.f-wrp li span {
    width: 150px;
    height: 150px;
    border-radius: 100%;
    overflow: hidden;
    display: block;
}

.value-main-wrpp.partner-main-wrp {
    padding: 100px 0 180px;
    background: #f8f8f8;
}

.testimonials-main-wrpp.f-wrp {
    background: #F1F1F1;
    padding: 50px 0 125px;
}

.othr-service-main-wrp.f-wrp {
    background-color: #f8f8f8;
    padding: 120px 0 120px;
}

.project-main-footer {
    /* background: #1D1D1B; */
    background-color: var(--ThemeBG);
}

.foot-logo-wrp {
    padding-top: 10px;
    padding-bottom: 10px;
    border-right: 2px solid #fff;
    padding-right: 25px;
    height: 100%;
    display: flex;
    align-items: center;
}

.contact-form-wrp.f-wrp {
    padding-bottom: 15px;
    padding-top: 8px;
}

.contact-form-wrp.f-wrp .foot-whtsap-btn {
    display: flex;
    align-items: center;
    padding: 12px 30px;
    border: 1px solid #f8f8f8;
    border-radius: 100px;
    font-weight: 500;
    width: max-content;
    font-size: 13px;
    color: #fff;
    cursor: pointer;
}

.contact-form-wrp.f-wrp .foot-whtsap-btn img {
    width: 20px;
    margin-right: 12.5px;
    display: inline-block;
}


.foot-form {
    display: flex;
    align-items: center;
    position: relative;
}

.foot-form .form-control {
    height: 40px;
    background-color: #1D1D1B;
    border: none;
    border-bottom: 1px solid #fff;
    width: 100%;
    padding: 0 40px;
    color: #fff;
}

.foot-form .form-control::placeholder {
    color: #C6C6C6;
}

.foot-form .form-control:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
}

.foot-whtsap {
    position: absolute;
    height: 100%;
    width: 20px;
    left: 10px;
    top: 0;
}

.foot-whtsap img {
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.foot-form button {
    background: #1D1D1B;
    position: absolute;
    right: 0;
    z-index: 99;
    cursor: pointer;
    height: 100%;
    width: 30px;
    border: navajowhite;
    border-bottom: 1px solid #fff;
}

.social-list.f-wrp ul {
    display: flex;
    padding-left: 25px;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
}

.social-list.f-wrp ul img {
    width: 30px;
    display: block;
}

.foot-logo-wrp .logo-wrp img {
    width: 55px;
    margin: 0 auto;
}

.foot-logo-wrp .logo-wrp .foottag img {
    width: 100%;
    max-width: 140px;

}

.each-foot-link {
    margin-bottom: 15px;
}

.menu-icon,
.mob-contnt-wrp,
.mob-list-wrp {
    display: none !important;
}

ul.partner-list.f-wrp {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

ul.partner-list.f-wrp li {
    width: auto;
    margin: 0 !important;
    margin-bottom: 15px !important;
}


.fileUpload {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
}

.fileUpload label {
    width: 100%;
    padding: 16.5px 14px !important;
}

.fileUpload label img {
    max-width: 150px;
    margin: 0 auto;
}

#prop-img {
    opacity: 0;
    width: 0;
    height: 0;
    display: none;
}

.form-title {
    font-family: 'Microsoft JhengHei' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    color: #1D1D1B !important;
}

.sales-checkbox label {
    width: 50% !important;
    margin: 0 !important;
}

.sales-checkbox label span {
    font-size: 14px !important;
}

.copy-txt p {
    text-align: center;
}





.pc-icon {
    display: none !important;
}

.main-header.scrolled .logo-wrp img {
    width: 35px;
}

.mob-icon .logo-wrp img {
    width: 35px;
}
.mob-icon{
    background-color: rgba(0, 0, 0, 0.05) !important;
}
.mob-icon:hover{
    background-color: rgba(0, 0, 0, 0.1) !important;
}
.close-btn {
    padding: 1px;
    display: inline-block;
    width: auto;
    cursor: pointer;
}

.menu-icon {
    display: block !important;
}

.custom-navbar {
    /* background-color: transparent !important; */
    box-shadow: none !important;
}

.mob-draw-wrp .MuiDrawer-paper {
    /* width: 100% !important; */
    /* background: rgba(29, 29, 27, 0.80); */
    padding: 15px;
    padding-right: 0;
    border-radius: 15px 0 0 15px;
    backdrop-filter: blur(2px);
    background: rgba(29, 29, 27, 0.80);
}

.close-btn span {
    display: block;
    /* width: 35px; */
    float: right;
}

.menu-icon img {
    width: 30px;
}

.close-btn img {
    width: 20px;
}

.mob-draw-wrp .MuiTypography-root {
    text-align: left;
    color: #fff;
    padding-left: 10px;
}

.main-header.scrolled .custom-navbar {
    padding-top: 0;
    padding-bottom: 0;
}

.main-header.scrolled .custom-navbar {
    background-color: #434547 !important;
}


.main-header .custom-header .logo-wrp span svg path {
    stroke: #1D1D1B !important;
}
.main-header.outside-home-wrp .custom-header .logo-wrp span svg path{
    stroke: #fff !important;
}
.main-header.scrolled .custom-header .logo-wrp span svg path,
.inside-home-wrp .custom-header .logo-wrp span svg path {
    stroke: #fff !important;
}

.mob-drawer ul {
    padding-left: 25px;
    display: block;
    clear: both;
}

.mob-drawer ul li {
    margin-bottom: 10px;
}

.mob-drawer ul li.Home,
.mob-drawer ul li.Eligibility,
.mob-drawer ul li.Listings {
    margin-bottom: 5px;
    padding-bottom: 10px;
    border-bottom: 1px solid #fff;
}

.mob-drawer ul li.Listings {
    margin-bottom: 30px;
}

.mob-drawer ul li a>div {
    padding-left: 0 !important;
}


.main-header .logo-wrp span a img{
    display: none;
    width: 110px;
}
.main-header.scrolled .logo-wrp span a img{
    width: 110px;
}

.main-header.project-main-header.outside-home-wrp .logo-wrp span a img.logo-default{
    display: block;
}
.main-header.project-main-header:not(.outside-home-wrp) .logo-wrp span a img.logo-sec{
    display: block;
}




.image-popup-btn{
    display: block;
    width: 100%;
    padding: 0;
    background: transparent;
    border-radius: 15px;
    border: none;
    cursor: pointer;
    height: 100%;
}
.image-popup-btn img{
    height: 100%;
    width: 100%;
    display: block;
    object-fit: cover;
    object-position: center;
}







@media only screen and (min-width: 992px) {

    .align-class1 {
        float: left;
        width: max-content;
    }

    .align-class2 {
        width: max-content;
        display: table;
        margin: 0 auto;
        float: none;
    }

    .align-class3 {
        width: max-content;
        float: none;
        display: table;
        margin: 0 auto;
    }

    .align-class4 {
        width: max-content;
        float: right;
    }
}

@media only screen and (min-width: 767px) {
    ul.partner-list.f-wrp {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    ul.partner-list.f-wrp li {
        width: auto;
        margin: 0 !important;
        margin-bottom: 15px !important;
    }
}

@media only screen and (max-width: 767px) {
    .value-main-wrpp .flextec .each-abt-blk {
        flex-direction: column;
    }

    .value-main-wrpp .flextec .each-abt-blk .cont-blk {
        padding-left: 0;
        padding-top: 25px;
    }

    .foot-logo-wrp {
        border-right: none;
        border-bottom: 2px solid #fff;
        padding-bottom: 25px;
        margin-bottom: 25px;
    }

    .social-list.f-wrp ul,
    .foot-sub-head,
    .copy-txt {
        justify-content: center;
        padding-left: 0;
        text-align: center;
    }

    .copy-txt {
        margin-bottom: 20px;
    }

    .foot-sub-head {
        padding-top: 45px;
    }

    .each-foot-link ul li {
        justify-content: center !important;
    }

    ul.partner-list.f-wrp {
        justify-content: flex-start;
    }



}

/* @media only screen and (max-width: 600px) {
   
} */

@media only screen and (max-width: 599px) {
    .project-main-header {
        height: auto;
        padding: 0 !important;
    }


    .pc-contnt-wrp {
        display: none !important;
    }

    .mob-contnt-wrp {
        display: flex !important;
        height: 100% !important;
        padding-top: 55px;
    }

    .mob-draw-wrp .MuiDrawer-paper {
        width: 100% !important;
        /* background: #434547; */
    }

    .banner-counter {
        padding-top: 40px;
    }

    .mob-banner .banner-logo {
        width: 25vw;
        display: block;
        margin: 0 auto;
    }

    .mob-banner .banner-title {
        width: 50vw;
        display: block;
        margin: 0 auto;
        margin-top: 30px;
    }

    .mob-contnt-wrp .banner-btn {
        width: 100%;
        border: 1px solid #C6C6C6;
        top: 22px;
        position: relative;
        width: 100%;
    }

    .PC-slide {
        display: none !important;
    }

    .mob-list-wrp {
        display: block !important;
    }

    .mob-list-wrp .each-list-items {
        margin-bottom: 25px;
    }

    .list-item-blk.f-wrp {
        min-height: 285px !important;
    }

    .mob-list-wrp .list-item-blk.f-wrp {
        min-height: 100% !important;
    }

    .list-slider-wrp {
        padding-bottom: 0 !important;
    }

    .list-header ul li a {
        display: none;
    }

    .listing-main-wrapp.f-wrp {
        padding-bottom: 40px;
    }

    .value-main-wrpp.f-wrp {
        padding-top: 40px;
    }

    .partner-main-wrp {
        padding-bottom: 60px !important;
    }
}

@media only screen and (max-width: 600px) {
    .value-main-wrpp .each-blk-sec {
        width: 50%;
    }

    ul.partner-list.f-wrp li {
        width: 50%;
    }

    ul.partner-list.f-wrp li .icon-blk {
        margin: 0 auto;
        float: none;
    }
}

@media only screen and (max-width: 400px) {

    .value-main-wrpp .each-blk-sec,
    ul.partner-list.f-wrp li {
        width: 100%;
    }

    .value-main-wrpp .each-abt-blk {
        margin: 0 auto;
        float: none;
    }
}

@media only screen and (min-width: 767px) and (max-width: 992px) {
    .social-list.f-wrp ul {
        max-width: 250px;
        margin: 0 auto;
        margin-top: 40px;
    }
}
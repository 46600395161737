.slick-slider .slick-arrow {
  height: 100%;
  top: 0;
  transform: translate(0, 0%);
  width: 30px;
  background-color: #0000004a;
  z-index: 9;

  .slick-prev {
    left: 0px;
  }

  .slick-next {
    right: 0px;
  }
}

.testimonials-main-wrpp {
  .slick-slider .slick-track, .slick-slider .slick-list {
    display: flex;
    .slick-slide{
      height: 100%;
      & > div{
        height: 100%;
        .each-testimonials-items.f-wrp{
          height: 100%;
          .test-con-wrp.f-wrp {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
        }
      }
    }
  }
  .each-testimonials-items.f-wrp {
    padding: 15px;

    .test-prof-img.f-wrp {
      width: 150px;
      height: 150px;
      margin: 0 auto;
      display: table;
      float: none;
      background: #fff;
      border-radius: 100%;
      padding: 10px;
      top: 73.5px;
      z-index: 9;
      margin-top: -75px;

      &::after {
        content: '';
        width: 150px;
        height: 77px;
        background: #fff;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        border: 0.5px solid #C6C6C6;
        border-radius: 130px 130px 0 0;
        border-bottom: none;
      }
    }

    .test-con-wrp.f-wrp {
      background: #fff;
      padding: 20px;
      // padding-top: 80px;
      border: 0.5px solid #C6C6C6;
      border-radius: 10px;
      p{
        margin: 0;
      }
      p,
      b {
        font-size: 14px;
      }
      b{
        margin-top: 15px;
        display: block;
      }
    }
  }

}

.testimonials-main-wrpp {
  padding-bottom: 60px;

  .slick-prev {
    right: calc(50% + 15px);
    left: unset;
  }

  .slick-next {
    left: calc(50% + 15px);
    right: unset;
  }

  .slick-slider .slick-arrow {
    height: 34px;
    bottom: -55px;
    transform: translate(0, 0%);
    width: 40px;
    background-color: #C6C6C6;
    z-index: 9;
    top: unset;
    border-radius: 5px;
    transition: 0.5s all ease;
  }

  .slick-next:before {
    content: url('./../../assets/images/icons/ArrowRight.svg');
    opacity: 1;
  }

  .slick-prev:before {
    content: url('./../../assets/images/icons/ArrowLeft.svg');
    opacity: 1;
  }

  .slick-slider .slick-arrow:hover {
    background: #1D1D1B;
    transition: 0.5s all ease;
  }
}
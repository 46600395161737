.value-grid-wrp {
  .each-abt-blk {
    border: 0.5px solid #C6C6C6;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);

    .icon-blk {
      padding-bottom: 10px;

      span {
        width: 110px;
        height: 110px;

        img {
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .cont-blk {
      label {
        font-weight: 400;
        color: #134870;
        font-size: 16px;
        text-transform: uppercase;
      }
    }
  }
}
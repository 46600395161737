.slick-slider .slick-arrow {
  height: 100%;
  top: 0;
  transform: translate(0, 0%);
  width: 30px;
  background-color: #0000004a;
  z-index: 9;

  .slick-prev {
    left: 0px;
  }

  .slick-next {
    right: 0px;
  }
}

.partner-slide {
  @media only screen and (min-width: 1024px) {
    & {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &.hide {
    display: none !important;
  }
}

.Swiperpartner-slide {
  height: auto !important;

  .swiper-slide {
    background: transparent;
  }
}

.partner-slide {
  padding-top: 45px;
  padding-bottom: 25px;
  .each-partner-sec {
    // padding-top: 45px;

    @media only screen and (min-width: 1024px) {
      & {
        padding-left: 25px;
        padding-right: 25px;
      }
    }

    span {
      display: block;
      margin: 0 auto;
      // height: 90px;
      height: 100px;
      // padding: 10px;
      // border-radius: 10px;
      // box-shadow: 1px 1px 3px 1px #e4e4e4;



      img {
        object-fit: contain;
        object-position: center;
        height: 100%;
      }

      // &.partner1 {
      //   width: 235px;
      //   height: 115px;
      // }

      // &.partner2 {
      //   width: 207.83px;
      //   height: 115px;
      // }

      // &.partner3 {
      //   width: 115px;
      //   height: 115px;
      // }

      // &.partner4 {
      //   width: 272.84px;
      //   height: 115px;
      // }

      // &.partner5 {
      //   width: 288px;
      //   height: 115px;
      // }
    }
  }
}

.partner-grid {
  display: flex;
  justify-content: space-between;
  max-width: 850px;
  align-items: center;

  &.hide {
    display: none !important;
  }

  .each-partner-sec {
    span {
      display: block;

      img {
        object-fit: contain;
        object-position: center;
        height: 100%;
      }

      &.partner1 {
        width: 290px;
        height: 114.96px;
      }

      &.partner2 {
        width: 207.83px;
        height: 142px;
      }

      &.partner3 {
        width: 142px;
        height: 142px;
      }

      &.partner4 {
        width: 272.84px;
        height: 142px;
      }

      &.partner5 {
        width: 288px;
        height: 142px;
      }
    }
  }
}
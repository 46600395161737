.serviceListing-wrp {
  max-width: 100%;
  margin: 0 auto;
  float: left;

  .each-property-items {
    a {
      padding: 45px;
      background-color: var(--Text);
      border-radius: 30px;
      .propList-bg {
        border-radius: 0px;

        .serviceIcon{
          display: block;
          float: left;
          svg{
            width: 100%;
            height: 75px;
          }
        }
        &::after{
          content: '';
          display: none;
        }

      }

      .propList-item-blk {
        
        h1,
        p {
          color: var(--white);
        }
        h1{
          font-size: 20px;
          padding-bottom: 10px;
        }

      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    background-color: var(--white);
    
    svg {
      path{
        stroke: var(--Text);
      }
    }
  }

  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    top: var(--swiper-navigation-top-offset, 50%);
  }

  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    top: var(--swiper-navigation-top-offset, 50%);
  }

}

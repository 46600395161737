.auth-layout {
    max-width: 1980px;
    margin: 0 auto;
    display: flex;
    width: 100%;
    position: relative;
    background: #F8F8F8;

    .auth-page-layout {
        width: 50%;
        min-width: 700px;

        @media only screen and (max-width: 991px) {
            & {
                width: 100%;
                min-width: 100%;
            }
        }

        .flex-box {
            display: flex;
            justify-content: flex-end;
            padding: 65px 65px 55px 0;

            @media only screen and (max-width: 991px) {
                & {
                    justify-content: center;
                    padding-right: 20px;
                    padding-left: 20px;
                }
            }

            .form-sec-wrp {
                width: 590px;

                .back-btn-wrp {
                    position: absolute;
                    left: 0;
                    top: -20px;

                    button {
                        font-size: 14px;
                        font-weight: 700;
                        color: #1D1D1B;
                        min-width: 10px;

                        svg {
                            transform: rotate(225deg);
                        }
                    }
                }

                .auth-logo {
                    height: 95px;
                    display: block;
                    width: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        object-position: center;
                    }
                }

                .app-text-input-container {
                    .MuiFormControl-root {
                        label {
                            font-family: 'Microsoft JhengHei' !important;
                            font-weight: 400;
                        }

                        .MuiInputLabel-shrink {
                            transform: translate(0px, -12px) scale(1);
                        }

                        .MuiInputBase-formControl input {
                            font-size: 13px;
                            padding: 8.5px 14px;
                            line-height: 14px;
                            height: 1.4375em;
                            background-color: #ffffff;
                            font-family: 'Microsoft JhengHei' !important;
                        }
                    }

                    .form-group .MuiFormHelperText-root {
                        position: absolute;
                        right: -10px;
                        bottom: 0px;
                        padding-left: 5px;
                        padding-right: 5px;
                        font-size: 10px;
                        color: red;
                    }
                }

                .register-radio-btn {
                    h3 {
                        font-size: 26px;
                        font-family: 'Microsoft JhengHei' !important;
                        color: #1D1D1B;
                        padding-bottom: 25px;
                        font-weight: 700;
                    }

                    svg {
                        color: #1D1D1B;
                        font-size: 15px;
                    }
                }

                .password-requirement-points {
                    padding-top: 13px;

                    ul {
                        margin: 0;

                        li {
                            float: left;
                            display: flex;
                            align-items: center;
                            width: 50%;
                            font-size: 12px;
                            margin-bottom: 5px;
                            color: #1D1D1B;
                            position: relative;

                            &::before {
                                content: '';
                                width: 20px;
                                height: 20px;
                                display: block;
                                background-color: rgba(67, 126, 247, 0.25);
                                margin-right: 5px;
                                border-radius: 100%;
                            }

                            &::after {
                                content: "";
                                width: 5px;
                                height: 9px;
                                border: 2px solid var(--nav-text);
                                position: absolute;
                                left: 7px;
                                top: 4px;
                                border-left: 0;
                                border-top: 0;
                                transform: rotate(45deg);
                                opacity: 0;
                            }

                            &.checked {
                                &::after {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }

                .row {
                    margin-top: 10px;

                    .col-xs-12 {
                        padding: 0;

                        .app-text-input-container {
                            padding-top: 25px;
                        }
                    }

                    .col-lg-6:nth-child(1) {
                        .app-text-input-container {
                            padding-right: 15px;
                            padding-top: 10px;
                            @media only screen and (max-width: 992px) {
                                & {
                                    padding-right: 0px;
                                }
                            }
                        }
                    }

                    .col-lg-6:nth-child(2) {
                        .app-text-input-container {
                            padding-left: 15px;
                            padding-top: 10px;
                            @media only screen and (max-width: 992px) {
                                & {
                                    padding-top: 25px;
                                    padding-left: 0px;
                                }
                            }
                        }
                    }
                }

                .custom-checkbox {
                    margin-top: 15px;

                    svg {
                        color: #1D1D1B;
                        font-size: 20px;
                    }

                    .MuiFormControlLabel-label {
                        font-family: 'Microsoft JhengHei' !important;
                        font-size: 14px;
                        color: var(--text);

                        a {
                            font-weight: 600;
                            color: var(--text);
                            font-size: 13px;
                        }
                    }
                }

                .additional-btn {
                    width: 100%;
                    max-width: 550px;
                    margin: 0 auto;
                    padding-top: 35px;

                    p {
                        margin: 0;
                        font-size: 13px;
                        color: var(--text);
                        font-family: 'Microsoft JhengHei' !important;
                        a,
                        button {
                            font-weight: 700;
                            font-size: 13px;
                            cursor: pointer;
                            color: var(--text);
                            font-family: inherit;
                        }
                        button {
                            min-width: 25px;
                        }
                        &.flexP{
                            display: flex;
                            align-items: center;
                            gap: 5px;
                            flex-wrap: wrap;
                        }
                    }
                    .rememberMe {
                        svg{
                            color: #1D1D1B;
                        }
                    }
                }

                .auth-btn {
                    background: #1D1D1B !important;
                    color: #fff !important;
                    padding: 8px 30px !important;
                    text-transform: none !important;
                    width: 100%;
                }

                .user-type-wrp {
                    display: flex;
                    max-width: 550px;
                    margin: 0 auto;
                    float: none;
                    min-height: 300px;
                    align-items: center;
                    padding-top: 35px;
                    gap: 30px;
                    flex-wrap: wrap;
                    width: auto;
                    justify-content: center;

                    @media only screen and (max-width: 550px) and (min-width: 360px) {
                        & {
                            display: grid;
                            grid-template-columns: repeat(2);
                            grid-template-areas: " box1 box1"
                                "box2 box3";
                            justify-items: center;
                        }
                    }

                    a {
                        border: 1px solid #1D1D1B;
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        align-items: center;
                        gap: 20px;
                        padding: 15px;
                        border-radius: 10px;
                        transform: scale(1);
                        transition: 0.5s all;
                        cursor: pointer;
                        flex: 1;
                        max-width: fit-content;

                        &:nth-child(1) {
                            grid-area: box1;
                        }

                        &:nth-child(2) {
                            grid-area: box2;
                        }

                        &:nth-child(3) {
                            grid-area: box3;
                        }

                        &:hover,
                        &:focus {
                            transform: scale(1.05);
                            transition: 0.5s all;
                            cursor: pointer;
                        }

                        img {
                            width: 120px;
                        }

                        p {
                            border: 1px solid #1D1D1B;
                            padding: 6px 5px;
                            text-align: center;
                            border-radius: 5px;
                            font-size: 14px;
                            width: 100%;
                            margin: 0;
                        }
                    }
                }

                .form-tile {
                    max-width: 550px;
                    margin: 0 auto;

                    h3 {
                        font-size: 26px;
                        font-family: "Microsoft JhengHei" !important;
                        color: #1D1D1B;
                        padding-bottom: 25px;
                        font-weight: 700;
                    }
                }
            }
        }
    }

    .form-img-wrp {
        width: 50%;
        display: block;
        position: relative;

        span {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;

            img {
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }

    &.login-wrp {
        .auth-page-layout {
            .flex-box {
                &.form-open {
                    padding-top: 25px;

                    @media only screen and (max-width: 599px) {
                        & {
                            padding-top: 115px;
                        }
                    }
                }

                &.form-close {
                    padding-top: 25px;
                    padding-bottom: 25px;
                    min-height: calc(100vh - 85px);
                    align-items: center;

                    @media only screen and (max-width: 599px) {
                        & {
                            padding-top: 115px;
                        }
                    }
                }

                .form-sec-wrp {
                    .auth-logo {
                        height: 160px;
                    }

                    .form-tile {
                        h3 {
                            padding-top: 10px;
                            text-align: center;
                            padding-bottom: 5px;
                        }
                    }
                }
            }
        }
    }
}

.auth-single-pg {
    .form-sec-wrp {
        min-height: calc(100vh - 85px);
        align-items: center;
        display: flex;
    }

    .auth-form {
        max-width: 550px;
        margin: 0 auto;

        .form-tile {
            padding-bottom: 30px;
            h3 {
                font-size: 26px;
                font-family: "Microsoft JhengHei" !important;
                color: #1D1D1B;
                padding-bottom: 10px;
                font-weight: 700;
            }

            h4 {
                font-size: 16px;
                font-family: "Microsoft JhengHei" !important;
                color: #1D1D1B;
                padding-bottom: 25px;
                font-weight: 700;
            }
        }

        .auth-btn {
            background: #1D1D1B !important;
            color: #fff !important;
            padding: 8px 30px !important;
            text-transform: none !important;
        }

        .app-text-input-container {
            .MuiFormControl-root {
                label {
                    font-family: 'Microsoft JhengHei' !important;
                    font-weight: 400;
                }

                .MuiInputLabel-shrink {
                    transform: translate(0px, -12px) scale(1);
                }

                .MuiInputBase-formControl input {
                    font-size: 13px;
                    padding: 8.5px 14px;
                    line-height: 14px;
                    height: 1.4375em;
                    background-color: #ffffff;
                    font-family: 'Microsoft JhengHei' !important;
                }
            }

            .form-group .MuiFormHelperText-root {
                position: absolute;
                right: -10px;
                bottom: 0px;
                padding-left: 5px;
                padding-right: 5px;
                font-size: 10px;
                color: red;
            }
        }
    }
}
.footer-revamp-wrp {

  .main-footer {
    background-color: #e8e8ec;
    padding: 75px 0 75px;

    .foot-logo-box {
      .logo-wrp {
        // padding-right: 75px;

        img {
          width: 100%;
          max-width: 200px;
          // margin: auto;
          padding-bottom: 15px;
        }

        p {
          margin: 0;
          padding-bottom: 10px;
          color: #2B2B2B;
          font-family: Poppins;
          font-size: 16px;
          font-weight: 500;
          text-align: left;

          &.contact {
            display: flex;
            gap: 8px;
          }
        }

      }
    }

    .each-foot-link {
      .foot-sub-head {
        color: #1A1440;
        font-family: Poppins;
        font-size: 19.4px;
        font-weight: 500;
        text-align: left;
        padding-left: 0;
        margin: 0;
        padding-bottom: 15px;
      }

      ul {
        margin: 0;

        li {
          margin-bottom: 10px;

          a {
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            color: #2B2B2B;

          }
        }
      }
    }

    .email-subscription-box {
      display: flex;
      border: 1px solid #333;
      background-color: var(--white);
      border-radius: 50px;
      overflow: hidden;
      gap: 10px;

      * {
        border: none !important;
      }

      .subscription-btn {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        position: relative;
        top: 8px;
        right: 8px;
        max-width: 40px;
        min-width: 40px;
        padding: 0;

        svg {
          width: 100%;
          height: 100%;

          circle {
            fill: var(--ThemeBG);
          }

          path {
            fill: var(--white)
          }
        }
      }

      .mui-focused {}
    }
  }

  .copy-foot {
    background: #1A1440;
    padding: 17px 0;

    p,
    li {
      color: #AAAAAA;
      a{
        color: inherit;
      }
    }

    .copy-txt {
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        text-align: left;

      }
    }

    .link-list {
      ul {
        margin: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
  }
}